import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class KasesEditSettingsRoute extends Route {
  @service store;
  @service router;
  layout = 'custom-aside';
  kaseV2 = null;

  async model() {
    const kase = this.modelFor('authenticated.kases.manage');
    this.kaseV2 = await this.store.findRecord('v2/kase', kase.get('id'));
    return kase;
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('kase', model);
    controller.set('kaseV2', this.kaseV2);
    controller.set('tcoId', this.kaseV2.get('tcoId'));
  }
}
