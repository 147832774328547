import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { set, get, computed } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default Model.extend({
  api: service(),

  asdExportedAt: attr('moment-datetime'),
  asdType: attr('string'),
  bassMollettExportedAt: attr('moment-datetime'),
  batesvilleExportedAt: attr('moment-datetime'),
  cfsExportedAt: attr('moment-datetime'),
  ciclopsEventName: attr('string'),
  ciclopsEventType: attr('string'),
  ciclopsExportedAt: attr('moment-datetime'),
  tributePrintEventName: attr('string'),
  tributePrintEventType: attr('string'),
  tributePrintExportedAt: attr('moment-datetime'),
  deceasedLastName: attr('string'),
  duration: attr('number', { defaultValue: 60 }),
  employeeArrivalTime: attr('moment-datetime'),
  eventAttendees: hasMany('eventAttendee', { async: true }),
  eventStaffMembers: hasMany('eventStaffMember', { async: true }),
  eventTypeName: attr('string'),
  eventVehicles: hasMany('eventVehicle', { async: true }),
  exportToBassMollett: attr('boolean'),
  exportToBatesville: attr('boolean'),
  exportToCFS: attr('boolean'),
  exportToCiclops: attr('boolean'),
  exportToTributePrint: attr('boolean'),
  exportToFrazer: attr('boolean'),
  exportToFrontrunner: attr('boolean'),
  exportToFuneralTech: attr('boolean'),
  exportToFuneralone: attr('boolean'),
  exportToMessenger: attr('boolean'),
  exportToTCO: attr('boolean'),
  exportToTukios: attr('boolean'),
  exportToViewlogies: attr('boolean'),
  familyArrivalTime: attr('moment-datetime'),
  frazerExportedAt: attr('moment-datetime'),
  frontrunnerEventType: attr('string'),
  frontrunnerExportedAt: attr('moment-datetime'),
  funeralTechEventType: attr('string'),
  funeralTechExportedAt: attr('moment-datetime'),
  funeraloneExportedAt: attr('moment-datetime'),
  inquiry: belongsTo('inquiry', { async: true }),
  kase: belongsTo('kase', { async: true }),
  location: belongsTo('location', { async: false }),
  messengerEventType: attr('string'),
  messengerExportedAt: attr('moment-datetime'),
  name: attr('string'),
  noEndTime: attr('boolean'),
  notes: attr('string'),
  pending: attr('boolean', { defaultValue: false }),
  place: belongsTo('place', { async: false }),
  room: belongsTo('room', { async: false }),
  startsAt: attr('moment-datetime'),
  tcoEventType: attr('string'),
  tukiosExportedAt: attr('moment-datetime'),
  viewlogiesEventType: attr('string'),
  viewlogiesExportedAt: attr('moment-datetime'),
  webcastPin: attr('string'),
  webcastUrl: attr('string'),

  hour: computed('startsAt', function() {
    if (!this.startsAt) {
      return null;
    }
    return this.startsAt.hour();
  }),

  endsAt: computed('startsAt', 'duration', function() {
    if (!this.startsAt) {
      return null;
    }
    return moment(this.startsAt).add(this.duration, 'minutes');
  }),

  startsAtUnix: computed('startsAt', function() {
    const startsAt = this.startsAt;
    return startsAt ? startsAt.unix() : null;
  }),

  endsAtUnix: computed('endsAt', function() {
    const endsAt = this.endsAt;
    return endsAt ? endsAt.unix() : null;
  }),

  formattedFrazerExportedAt: computed('frazerExportedAt', function() {
    return moment(this.frazerExportedAt).format('LLL');
  }),

  get formattedFrontrunnerExportedAt() {
    return moment(this.frontrunnerExportedAt).format('LLL');
  },

  get formattedFuneralTechExportedAt() {
    return moment(this.funeralTechExportedAt).format('LLL');
  },

  // TODO: Test removing try/catch
  frazerExportable: computed(
    'place.{line1,name,city,state,zipCode,phoneNumber}',
    'startsAt',
    'exportToFrazer',
    function() {
      try {
        const place = this.place;
        if (
          this.startsAt &&
          this.place &&
          get(place, 'line1') &&
          get(place, 'name') &&
          get(place, 'city') &&
          get(place, 'state') &&
          get(place, 'zipCode') &&
          get(place, 'phoneNumber')
        ) {
          return true;
        } else {
          set(this, 'exportToFrazer', false);
        }
      } catch (e) {
        return false;
      }
    }
  ),

  tukiosExportable: computed(
    'place.{line1,name,city,state,zipCode}',
    'duration',
    function () {
        const place = this.place;
        if (
          this.duration &&
          this.place &&
          get(place, 'line1') &&
          get(place, 'name') &&
          get(place, 'city') &&
          get(place, 'state') &&
          get(place, 'zipCode')
        ) {
          return true;
        } else {
          return false;
        }
      }
  ),

  formattedFuneraloneExportedAt: computed('funeraloneExportedAt', function() {
    return moment(this.funeraloneExportedAt).format('LLL');
  }),

  // TODO: Test removing try/catch
  funeraloneExportable: computed(
    'name',
    'startsAt',
    'place',
    'place.name',
    'exportToFuneralone',
    function() {
      try {
        const place = this.place;
        if (this.name && this.place && get(place, 'name')) {
          return true;
        } else {
          set(this, 'exportToFuneralone', false);
        }
      } catch (e) {
        return false;
      }
    }
  ),

  // TODO: Test removing try/catch
  messengerExportable: computed(
    'place',
    'messengerEventType',
    'exportToMessenger',
    function() {
      try {
        if (this.messengerEventType && this.place) {
          return true;
        } else {
          set(this, 'exportToMessenger', false);
        }
      } catch (e) {
        return false;
      }
    }
  ),

  // TODO: Test removing try/catch
  bassMollettExportable: computed(
    'startsAt',
    'place',
    'place.name',
    'exportToBassMollett',
    function() {
      try {
        const place = this.place;
        if (this.startsAt && this.place && get(place, 'name')) {
          return true;
        } else {
          set(this, 'exportToBassMollett', false);
        }
      } catch (e) {
        return false;
      }
    }
  ),

  // TODO: Test removing try/catch
  viewlogiesExportable: computed(
    'place.name',
    'viewlogiesEventType',
    'startsAt',
    'exportToViewlogies',
    function() {
      try {
        const place = this.place;
        if (
          this.startsAt &&
          this.place &&
          get(place, 'name') &&
          this.viewlogiesEventType
        ) {
          return true;
        } else {
          set(this, 'exportToViewlogies', false);
        }
      } catch (e) {
        return false;
      }
    }
  ),

  formattedMessengerExportedAt: computed('messengerExportedAt', function() {
    return moment(this.messengerExportedAt).format('LLL');
  }),

  formattedCFSExportedAt: computed('cfsExportedAt', function() {
    return moment(this.cfsExportedAt).format('LLL');
  }),

  formattedCiclopsExportedAt: computed('ciclopsExportedAt', function() {
    return moment(this.ciclopsExportedAt).format('LLL');
  }),

  formattedTributePrintExportedAt: computed(
    'tributePrintExportedAt',
    function() {
      return moment(this.tributePrintExportedAt).format('LLL');
    }
  ),

  formattedBassMollettExportedAt: computed('bassMollettExportedAt', function() {
    return moment(this.bassMollettExportedAt).format('LLL');
  }),

  formattedViewlogiesExportedAt: computed('viewlogiesExportedAt', function() {
    return moment(this.viewlogiesExportedAt).format('LLL');
  }),

  formattedBatesvilleExportedAt: computed('batesvilleExportedAt', function() {
    return moment(this.batesvilleExportedAt).format('LLL');
  }),

  formattedTCOExportedAt: computed('tcoExportedAt', function() {
    return moment(this.tcoExportedAt).format('LLL');
  }),

  formattedTukiosExportedAt: computed('tukiosExportedAt', function() {
    return moment(this.tukiosExportedAt).format('LLL');
  }),

  cfsExportable: computed('name', 'exportToCFS', function() {
    try {
      if (this.name) {
        return true;
      } else {
        set(this, 'exportToCFS', false);
      }
    } catch (e) {
      return false;
    }
  }),

  frontrunnerExportable: computed(
    'place',
    'exportToFrontrunner',
    'frontrunnerEventType', function() {

    try {
      const invalidAddress = !this.place?.line1 || this.place?.line1 === '';
      const invalidType = !this.frontrunnerEventType || this.frontrunnerEventType === '';

      if (invalidAddress || invalidType) {
        this.set('exportToFrontrunner', false);
        return false;
      }

      return true;

    } catch (error) {
      return false
    }

  }),

  funeralTechExportable: computed(
    'place',
    'exportToFuneralTech',
    'funeralTechEventType', function() {

    try {
      const invalidAddress = !this.place?.line1 || this.place?.line1 === '';
      const invalidCity = !this.place?.city || this.place?.city === '';
      const invalidCountry = !this.place?.country || this.place?.country === '';
      const invalidState = !this.place?.state || this.place?.state === '';
      const invalidType = !this.funeralTechEventType || this.funeralTechEventType === '';
      const invalidZip = !this.place?.zipCode || this.place?.zipCode === '';
      const cantBeExported = invalidAddress || invalidCity || invalidCountry || invalidState || invalidType || invalidZip;


      if (cantBeExported) {
        this.set('exportToFuneralTech', false);
        return false;
      }

      return true;

    } catch (error) {
      return false
    }
  })
});
