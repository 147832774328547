import { run } from '@ember/runloop';
import RSVP from 'rsvp';
import { set, get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  queryParams: {
    token: {
      refreshModel: false,
      replace: true,
      as: 'token-id'
    }
  },

  session: service(),

  model() {
    const params = this.paramsFor('authenticated.misc-sales.edit');
    const clientInfoId = get(
      this,
      'session.session.authenticated.client.client_info_id'
    );

    return RSVP.hash({
      selection: this.store.findRecord('selection', params.selection_id),
      clientInfo: this.store.findRecord('clientInfo', clientInfoId),

      newRefund: this.store.createRecord('refund', {
        enteredBy: get(this, 'session.currentUser')
      })
    });
  },

  setupController(controller, models) {
    this._super(...arguments);
    controller.setProperties(models);
    set(models, 'newRefund.person', controller.get('selection.purchaser'));
    set(models, 'newRefund.refundTo', controller.get('selection.purchaser.name'));
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('selection_id', null);
      controller.set('showValidation', false);
    }
  },

  handleRefundSaveSuccess() {
    this.refresh();
    // this.send('refreshKaseFinancial');
    this.send('flashSuccess', 'Refund successfully saved!');
  },

  handleRefundRemoveSuccess() {
    this.refresh();
    // this.send('refreshKaseFinancial');
    this.send('flashSuccess', 'Refund successfully removed.');
  },

  handleError() {
    this.send(
      'flashError',
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
    );
  },

  actions: {
    cancelNewRefund() {
      if (confirm('This refund will be cancelled. Are you sure?')) {
        set(this.controller, 'showValidation', false);
        this.refresh();
      }
    },

    saveNewRefund(newRefund) {
      newRefund.setProperties({
        enteredBy: get(this, 'session.currentUser')
      });

      newRefund
        .save()
        .then(
          run.bind(this, 'handleRefundSaveSuccess'),
          run.bind(this, 'handleError')
        );
    },

    removeRefund(refund) {
      refund
        .destroyRecord()
        .then(
          run.bind(this, 'handleRefundRemoveSuccess'),
          run.bind(this, 'handleError')
        );
    }
  }
});
