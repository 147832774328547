import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { dasherize } from '@ember/string';
import { startCase } from 'lodash-es/string';
import { inject as service } from '@ember/service';

const routeCase = (name) => dasherize(name);

export default class IntegrationServiceModel extends Model {
  @service intl;

  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') exportedAt;
  @attr('moment-datetime') exportUpdatedAt;
  @attr('string') category;
  @attr('string') client_id;
  @attr('string') description;
  @attr('string') display_name;
  @attr('string') domain_id;
  @attr('string') exportStatus;
  @attr('string') name;

  // ASD
  @attr('boolean') autoKaseSync;
  @attr('boolean') disableImport;
  @attr('string') importAfterDate;
  
  /** The following attributes are just for sending the params on creation */
  @attr('array') bestWishesCardTypes;
  @attr('boolean') avatar_override;
  @attr('boolean', { defaultValue: false}) generalPaymentDefault;
  @attr('boolean') locationBased;

  @attr('number') logo_id;
  @attr('number') tributeVideoUserId;
  @attr('number') websitePaymentTypeId;

  @attr('string') cemsites_blog_id;
  @attr('string') cemsites_client_uuid;
  @attr('string') cemsites_place_name;
  @attr('string') chartOfAccountsId;
  @attr('string') editAccountUrl;
  @attr('string') email;
  @attr('string') firm_id;
  @attr('string') hostname;
  @attr('string') key;
  @attr('string') locationId;
  @attr('string') logoName;
  @attr('string') new_contact_date;
  @attr('string') onboardingUrl;
  @attr('string') secret;
  @attr('string') statementDescriptor;
  @attr('string') tps_account_status;
  @attr('string') tpsAgreementUrl;
  @attr('string') tukios_funeral_home_location_id;
  @attr('string') tukios_funeral_home_location_name;
  @attr('string') username;
  @attr('string') website_url;

  // TFE
  @attr('string') tfe_kase_forms_migration_status;

  // The following are Location info attributes for Tribute Pay Accounts
  @attr('string') addressLine1;
  @attr('string') addressLine2;
  @attr('string') city;
  @attr('string') state;
  @attr('string') country;
  @attr('string') postalCode;

  @belongsTo('programmaticUser', { async: true }) programmaticUser;
  
  @hasMany('locationIntegrationService', { async: true })
  locationIntegrationServices;
  @hasMany('location', { async: true }) locations;
  
  get displayName() {
    if (this.name === 'cfs') {
      return this.intl.t('models.integration-service.displayName.cfs');
    } else if (this.name === 'frontrunner') {
      return this.intl.t('models.integration-service.displayName.frontRunner');
    } else if (this.name === 'funeralone') {
      return this.intl.t('models.integration-service.displayName.funeralOne');
    } else if (this.name === 'bass_mollett') {
      return this.intl.t('models.integration-service.displayName.bassMollett');
    } else if (this.name === 'cemsites') {
      return this.intl.t('models.integration-service.displayName.cemSites');
    } else if (this.name === 'asd') {
      return this.intl.t('models.integration-service.displayName.asd');
    } else if (this.name === 'ohio_edrs') {
      return this.intl.t('models.integration-service.displayName.ohioEdrs');
    } else if (this.name === 'tco' || this.name === 'tco_v2') {
      return this.display_name;
    } else if (this.name === 'domanicare') {
      return this.intl.t('models.integration-service.displayName.domaniCare');
    } else if (this.name === 'funeral_tech') {
      return this.intl.t('models.integration-service.displayName.funeralTech');
    } else if (this.name === 'tukios_v2') {
      return this.intl.t('models.integration-service.displayName.tukiosV2');
    } else if (this.name && this.name.includes('crakn')) {
      return startCase(this.name).replace('Crakn', this.intl.t('models.integration-service.displayName.tms'));
    } else if (this.name) {
      return startCase(this.name);
    } else {
      /** COMMENT: There was no use case for `this.name` having no value,
       * so I'm having it return "Unknown" for now. */
      return this.intl.t('models.integration-service.displayName.unknown');
    }
  }

  get routeName() {
    return `authenticated.kases.manage.integrations.${routeCase(this.name)}`;
  }

  get isTributeIntegration() {
    const tributeIntegrations = ['cfs','frazer','frontrunner','tco','tribute_video', 'tco_v2'];
  
    if (tributeIntegrations.includes(this.name)) {
      return true;
    }

    return false;
  }
}
