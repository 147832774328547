import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class KasesManageDocumentsAlbumsController extends Controller {
  @service api;
  @service flashes;
  @service intl;
  @service router;

  kase = null;
  user = null;

  @tracked pendingImport = false;

  @action
  addAlbumPhoto(album, photo) {
    album.photos.addObject(photo);
  }

  @action
  createNewAlbum() {
    this.router.transitionTo('authenticated.kases.manage.documents.albums.new');
  }

  @action
  delete(album) {
    album
      .destroyRecord()
      .then(() => this.flashes.addSuccess('Album successfully removed.'))
      .catch(() =>
        this.flashes.addError('Something went wrong, please try again.')
      );
  }

  @action
  removeCoverPhoto(album) {
    album.coverPhoto = null;
  }

  @action
  removePhoto(album, photo) {
    album.photos.removeObject(photo);
  }

  @action
  async importTributeWall() {
    this.pendingImport = true;

    const response = await this.api.json.get(
      `tco/kases/${this.kase.get('id')}/albums/import_tribute_wall`
    )

    if (response.ok && response.parsedJson?.success) {
      this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.documents.albums.manage.importTributeWall.success'));
      this.send('refreshModel');
    } else {
      this.flashes.addError(this.intl.t('authenticated.kases.manage.documents.albums.manage.importTributeWall.error'));
    }

    this.pendingImport = false;
  }

  @action
  save(album) {
    album.set('kase', this.kase);
    if (album.photos.length > 0) {
      const coverPhoto = album.get('coverPhoto.id')
        ? album.coverPhoto
        : album.photos.firstObject;
      album.set('coverPhoto', coverPhoto);
    }

    album
      .save()
      .then(() => {
        this.router.transitionTo(
          'authenticated.kases.manage.documents.albums.manage'
        );
        this.flashes.addSuccess('Album was successfully updated!');
      })
      .catch(() =>
        this.flashes.addError('Something went wrong, please try again.')
      );
  }

  @action
  setCoverPhoto(album, photo) {
    album.set('coverPhoto', photo);
  }
}
