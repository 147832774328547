import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';

export default class KasesEditEventsController extends Controller {
  @service flashes;
  @service router;
  @service store;
  @service session;

  kase = null;
  events = null;

  eventSortingDesc = ['startsAtUnix'];
  @sort('events', 'eventSortingDesc') sortedEvents;

  @action
  addNewEvent() { 
    const current_client = this.get('session').currentClient;

    this.store
      .createRecord('event', {
        kase: this.kase,
        exportToTCO: current_client.can_view_tco || current_client.can_view_tco_v2
      })
      .save()
      .then((newEvent) => {
        this.router.transitionTo('authenticated.kases.events.edit', newEvent);
      });
  }

  @action
  saveKase(value) {
    this.kase.hasNoEvents = value;

    this.kase
      .save()
      .then(() => this.flashes.addSuccess('Case has been updated!'))
      .catch(() =>
        this.flashes.addError('Something went wrong, please try again!')
      );
  }
}
