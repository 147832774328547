import Controller from '@ember/controller';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'package.name': validator('presence', true),
  'package.invoiceCategoryId': validator('presence', true),
});

export default class AdminGeneralPriceListsPackagesEditController extends Controller.extend(
  Validations
) {
  @service flashes;
  @service router;
  @service session;

  @tracked showValidations = false;

  get productQueryParams() {
    return {
      general_price_list_id: this.generalPriceList.get('id'),
      archived: false,
    };
  }

  get packageProducts() {
    return this.package.get('products');
  }

  @computed('package.{total,productsTotal}')
  get totalWarning() {
    return this.package.total < this.package.productsTotal;
  }

  @equal('package.pricingMethod', 'normal') isNormal;

  get displayTotalWarningColor() {
    if (this.showValidations && !this.isValidPricingMethodAmount) {
      return 'red'
    } else if (this.totalWarning) {
      return 'yellow'
    } else {
      return null
    }
  }

  get isValidPricingMethodAmount () {
    if (this.package.pricingMethod === 'amount') {
      return this.package.discountedTotal > 0;
    } else if (this.package.pricingMethod === 'fixed') {
      return this.package.calculatedDiscount > 0;
    } else {
      return true;
    }
  }

  @action
  addPackageProduct(product) {
    this.packageProducts.addObject(product);
  }

  @action
  savePackage() {
    this.validate().then(({ validations }) => {
      if (validations.get('isValid') && this.isValidPricingMethodAmount) {
        this.showValidations = false;
        this.package
          .save()
          .then(() => {
            this.router.transitionTo(
              'authenticated.admin.general-price-lists.edit.packages'
            );
            this.flashes.addSuccess('Package saved!');
          })
          .catch(() => {
            this.flashes.addError('Something went wrong. Please try again.');
          });
      } else {
        this.showValidations = true;
      }
    });
  }

  @action
  cancelPackage() {
    this.package.rollbackAttributes();
    this.router.transitionTo(
      'authenticated.admin.general-price-lists.edit.packages'
    );
  }

  @action
  removePackageProduct(product) {
    this.packageProducts.removeObject(product);
  }
}
