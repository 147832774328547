import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { set } from '@ember/object';
import { validator, buildValidations } from 'ember-cp-validations';
import ChangedRelationship from 'crakn/mixins/changed-relationships';

const Validations = buildValidations({
  name: validator('presence', true),
  price: validator('presence', true),
  'invoiceCategory.id': validator('presence', true),
  'productCategory.id': validator('presence', true)
});

export default Model.extend(ChangedRelationship, Validations, {
  name: attr('string'),
  description: attr('string'),
  shortDescription: attr('string'),
  imageUrl: attr('string'),
  price: attr('price'),
  cost: attr('price'),
  isTaxed: attr('boolean'),
  stateTax: attr('boolean'),
  localTax: attr('boolean'),
  hasSpecialTax: attr('boolean'),
  specialTaxId: attr('string'),
  isCustomizable: attr('boolean'),
  tracking: attr('boolean', { defaultValue: false }),
  isExportCogs: attr('boolean', { defaultValue: false }),
  archivedAt: attr('moment-datetime'),
  createdAt: attr('moment-datetime'),
  updatedAt: attr('moment-datetime'),
  isDiscretionary: attr('boolean', { defaultValue: false }),
  includeShipping: attr('boolean', { defaultValue: false }),
  shippingTotal: attr('price'),

  // Additional info
  manufacturer: attr('string'),
  model: attr('string'),
  material: attr('string'),
  interior: attr('string'),

  netsuiteId: attr('string'),

  invoiceCategory: belongsTo('invoiceCategory', { async: true }),
  preneedCategory: belongsTo('v2/preneedCategory', { async: true }),
  productCategory: belongsTo('productCategory', { async: true }),
  statementCategory: belongsTo('v2/statementCategory', { async: true }),
  statementSubcategory: belongsTo('statementSubcategory', { async: false }),
  generalPriceList: belongsTo('generalPriceList', { async: true }),

  priceChanges: hasMany('priceChange', { async: true }),
  costChanges: hasMany('costChange', { async: true }),

  packageSlotProducts: hasMany('v2/packageSlotProduct', { async: true }),
  packageSlots: hasMany('v2/packageSlot', { async: true }),
  packages: hasMany('package', { async: true }),

  // Used for rolling back hasMany and belongsTo relationships
  _invoiceCategory: null,
  _productCategory: null,
  _statementCategory: null,
  __statementSubcategory: null,

  saveRelationshipState() {
    set(this, '_invoiceCategory', this.invoiceCategory);
    set(this, '_productCategory', this.productCategory);
    set(this, '_statementCategory', this.statementCategory);
    set(this, '_statementSubcategory', this.statementSubcategory);
  },

  rollbackRelationships() {
    set(this, 'invoiceCategory', this._invoiceCategory);
    set(this, 'productCategory', this._productCategory);
    set(this, 'statementCategory', this._statementCategory);
    set(this, 'statementSubcategory', this._statementSubcategory);
  }
});
