import Model, { belongsTo, attr } from '@ember-data/model';
import { notEmpty, or } from '@ember/object/computed';
import { fullName, phoneNumber } from 'crakn/utils/people-helpers';
import { validator, buildValidations } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import { formatDateRange } from 'crakn/utils/date-helpers';

const Validations = buildValidations({
  firstName: validator('presence', {
    message: 'First or Last Name must be provided.',
    presence: true,
    dependentKeys: ['model.lastName'],
    disabled: notEmpty('model.lastName'),
  }),
  lastName: validator('presence', {
    message: 'First or Last Name must be provided.',
    presence: true,
    dependentKeys: ['model.firstName'],
    disabled: notEmpty('model.firstName'),
  }),
});

export default class V2PersonModel extends Model.extend(Validations) {
  @service api;

  @attr() meta;
  @attr('boolean') retired;
  @attr('number') yearsInCounty;
  @attr('number') yearsInOccupation;
  @attr('phone') faxNumber;
  @attr('phone') phonePrimary;
  @attr('phone') phoneSecondary;
  @attr('string', { defaultValue: 'Unknown' }) gender;
  @attr('string') interestsOrAchievements;
  @attr('string') affiliation;
  @attr('string') aka;
  @attr('string') ancestry;
  @attr('string') borough;
  @attr('string') church;
  @attr('string') education;
  @attr('string') email;
  @attr('string') emailSecondary;
  @attr('string') employer;
  @attr('string') employerYears;
  @attr('string') firstName;
  @attr('string') hispanicOrigin;
  @attr('string') industry;
  @attr('string') kaseAssociations;
  @attr('string') language;
  @attr('string') lastName;
  @attr('string') maidenName;
  @attr('string') maritalStatus;
  @attr('string') middleName;
  @attr('string') namePrefix;
  @attr('string') nameSuffix;
  @attr('string') netsuiteId;
  @attr('string') nickname;
  @attr('string') occupation;
  @attr('string') organizationsOrMemberships;
  @attr('string') race;
  @attr('string') religion;
  @attr('string') spouseFirstName;
  @attr('string') spouseLastName;
  @attr('string') spouseMaidenName;
  @attr('string') spouseMiddleName;

  @attr('boolean') insideCityLimits;
  @attr('string', { defaultValue: 'US' }) country;
  @attr('string') addressLine1;
  @attr('string') addressLine2;
  @attr('string') city;
  @attr('string') county;
  @attr('string') notes;
  @attr('string') ssn;
  @attr('string') ssnLastFour;
  @attr('string') state;
  @attr('string') township;
  @attr('string') zipCode;

  @attr('boolean') deceased;
  @attr('boolean') hasExistingKase;
  @attr('boolean') hispanic;
  @attr('moment-date') birthDate;
  @attr('moment-date') deathDate;
  @attr('moment-date') retirementDate;
  @attr('moment-datetime') archivedAt;
  @attr('string') contactAge;
  @attr('string') jewishName;
  @attr('string') kaseId;
  @attr('string') licenseNumber;
  @attr('string') printedName;

  @belongsTo('v2/address', { async: true }) birthplace;
  @belongsTo('v2/address', { async: true }) mailingAddress;
  @belongsTo('v2/funeral-decisions-person', { async: true }) funeralDecisionsPerson;
  @belongsTo('v2/profile', { async: true }) profile;

  @notEmpty('addressLine1', 'city', 'state', 'zipcode', 'country') addressCheck;

  @or('birthDate', 'deathDate') isBirthOrDeathPresent;
  @or('firstName', 'lastName') hasFirstOrLastName;

  get firstLastName() {
    if (!this.firstName && !this.lastName) {
      return null;
    }

    return [this.firstName, this.lastName].filter((i) => i).join(' ');
  }

  get contactAddress() {
    if (!this.addressLine1 && !this.addressLine2) {
      return null;
    }

    return [this.addressLine1, this.addressLine2].filter(Boolean).join(', ');
  }

  get contactCityState() {
    if (!this.city && !this.state && !this.zipCode) {
      return null;
    }

    const cityState = [this.city, this.state].filter(Boolean).join(', ');

    if (!this.state) {
      return [cityState, this.zipCode].filter(Boolean).join(', ');
    } else {
      return [cityState, this.zipCode].filter(Boolean).join(' ');
    }
  }

  get singleLineAddress() {
    if (!this.addressLine1 && !this.addressLine2 && !this.city && !this.state && !this.zipCode) {
      return null;
    }

    return [ this.addressLine1, this.addressLine2, this.city, this.state, this.zipCode,].filter(Boolean).join('+');
  }

  get name() {
    return fullName(this);
  }
  
  set name(value) {
    const names = value.split(' ');
    const [firstName] = names;
    const lastName = names.slice(1).join(' ');

    this.firstName = firstName;
    this.lastName = lastName;
    this.namePrefix = null;
    this.middleName = null;
    this.maidenName = null;
    this.nameSuffix = null;

    return value;
  }

  get nameNoMaiden() {
    return fullName(this, '', false);
  }

  get displayPhoneNumber() {
    return phoneNumber(this);
  }

  get typeaheadName() {
    return fullName(this) + (this.archivedAt ? ' (archived)' : '');
  }

  get birthDeathDates() {
    return formatDateRange(this.birthDate, this.deathDate);
  }

  exportToFuneralDecisions() {
    return new Promise((resolve, reject) =>
      this.api.json
        .post('funeral_decisions/contacts', { body: { id: this.id } })
        .then((response) => (response.ok ? resolve() : reject()))
    );
  }

  replace(substitute) {
    return new Promise((resolve, reject) => {
      const param = substitute ? `?substitute_id=${substitute.id}` : '';
      return this.api.json
        .delete(`v2/people/${this.id}/replace${param}`)
        .then((response) => (response.ok ? resolve() : reject()));
    });
  }
}
