import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminIntegrationsTcoV2Route extends Route {
  @service store;

  model() {
    return this.store.query('integrationService', {
      name: 44, // tco-v2
      not_category: 'accounting',
      per_page: 1000
    });
  }

  async setupController(controller) {
    super.setupController(...arguments);

    const v1TcoIntegrations = await this.store.query('integrationService', {
      name: 32, // tco
      not_category: 'accounting',
    });

    controller.set('v1TcoIntegrations', v1TcoIntegrations);
    controller.set('isLoading', false);
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
