// app/authenticators/custom.js
import Base from 'ember-simple-auth/authenticators/base';
import { inject as service } from '@ember/service';
import moment from 'moment';
import config from '../config/environment';
import { cancel } from '@ember/runloop';

export default class ApplicationAuthenticator extends Base {
  @service api;
  @service router;
  @service jwt;

  authenticate(options) {
    return new Promise((resolve, reject) => {
      this.api.json
          .post('login', { body: options })
          .then(response => {
            if (response.ok && response.parsedJson.session) {
              if (this.jwt.isSso()) this.jwt.scheduleAccessTokenRefresh();
              this.injectSession(response.parsedJson.session);
              resolve(response.parsedJson.session);
            } else if (response.parsedJson.migration_result) {
              reject(response.parsedJson.migration_result);
            } else {
              reject(response.parsedJson.error);
            }
          });
    });
  }

  invalidate() {
    return new Promise((resolve) => {
      this.api.json.delete('session')
      .then((resp) => {

        if (resp?.ok && resp?.parsedJson?.url) {
          // Logout from SSO
          this.jwt.logout(resp.parsedJson.url).then(() => {
            cancel(this._refreshTokenTimeout);
            delete this._refreshTokenTimeout;
            this.jwt.removeSsoTokens();
            resolve();

          }).catch(() => resolve());

        } else {
          resolve();
        }
      });
    });
  }

  restore() {
    return new Promise((resolve, reject) => {
      if (this.jwt.isSso()) {
        const now = this.jwt.now;

        const refresh_expired = (now >= +localStorage.getItem('sso_refresh_expires_at'));

        if (refresh_expired) {
          this.jwt.removeSsoTokens();
          this.router.transitionTo('login');
        } else {
          this.jwt.getNewAccessToken().then(()=>{
            this.getSession(resolve, reject);
          });
        }
      } else {
        this.getSession(resolve, reject);
      }
    });
  }

  getSession(resolve, reject) {
    this.api.json
    .get('session')
    .then(response => {
      if (response.ok) {
        this.injectSession(response.parsedJson.session);
        resolve(response.parsedJson.session);
      } else {
        reject(response.parsedJson.error);
      }
    });
  }

  injectSession(session) {
    const { client, user } = session;

    //API will need to be configured with the same prefix. See current_user_serializer.rb
    let prefix = config.deployTarget;
    if (prefix == 'production') {
      prefix = '';
    }

    const clientId = `${prefix}${client.id}`;
    const userId = `${prefix}${user.id}`;
    const clientType = client.client_type;

    // Configure Intercom. Prevent Mortuary College and Demo/Sandbox clients from sending to Intercom
    if (config.intercomId && clientType != 'Mortuary College' && !client.expires_at && user.intercom_token) {
      // Don't report company for TMS users that move between accounts
      // Still want to report to intercom because this is used in demos and in training
      if (user.is_superuser) {
        window.Intercom('boot', {
          app_id: config.intercomId,
          user_id: userId,
          user_hash: user.intercom_token,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          phone: user.mobile,
          created_at: moment(user.created_at).unix()
        });
      } else {
        window.Intercom('boot', {
          app_id: config.intercomId,
          user_id: userId,
          user_hash: user.intercom_token,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          phone: user.mobile,
          created_at: moment(user.created_at).unix(),
          company: {
            id: clientId,
            name: client.name,
            created_at: moment(client.created_at).unix(),
            plan: client.plan || 'Vox',
            client_type: clientType,
            call_volume: client.call_volume,
            pet_kases: client.pet_kases,
            human_kases: client.human_kases,
            total_discounted_kases: client.total_discounted_kases,
          }
        });
      }
    }
  }
}
