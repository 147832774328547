import Controller from '@ember/controller';
import { filter, filterBy } from '@ember/object/computed';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import { validator, buildValidations } from 'ember-cp-validations';
import money from 'money-math';

const Validations = buildValidations({
  'newInventoryTransaction.note': validator('presence', true),
  'newInventoryTransaction.quantity': validator('presence', true)
});

export default class AuthenticatedInventoryEditController extends Controller.extend(Validations) {
  @service flashes;
  @service intl;
  @service session;

  products = [];
  queryParams = ['redirectPath', 'redirectId'];
  redirectId = null;
  redirectPath = null;
  showSlider = false;
  
  @tracked showValidation = false;
  @tracked adjustmentType = 'Beginning Quantity';
  @tracked receiveType = 'Stock';

  @filterBy('addChangesAdjustments', 'isAdjustment', false) addChanges;
  @filterBy('allChanges', 'changeType', 'Add') addChangesAdjustments;
  @filterBy('allChanges', 'isAdjustment', true) adjustmentChanges;
  @filterBy('lessChangesAdjustments', 'isAdjustment', false) lessChanges;
  @filterBy('allChanges', 'changeType', 'Less') lessChangesAdjustments;

  @filter('inventoryTransactions.[]', function(change) {
    return !get(change, 'isNew');
  }) allChanges;

  get totalCost() {
    return (parseFloat(money.floatToAmount(this.inventory.cost)) * parseInt(this.inventory.quantity)).toFixed(2);
  }

  get adjustmentDisabled() {
    if (
      this.adjustmentType === 'Beginning Quantity' ||
      this.adjustmentType === ''
    ) {
      return true;
    } else {
      return false;
    }
  }

  get receiveDisabled() {
    return this.receiveType === '';
  }
  
  handleSaveSuccess() {
    this.flashes.addSuccess('Yay! You saved a product.');
    this.transitionToRoute('authenticated.inventory.index');
  }

  handleSaveError() {
    this.flashes.addError('Oops! There was an error when saving this product.');
  }

  createInventoryTransaction(
    inventoryTransaction,
    changeType,
    createdAt,
    inventory,
    isAdjustment,
    receiveType
  ) {
    set(inventoryTransaction, 'price', get(inventory, 'price'));
    set(inventoryTransaction, 'cost', get(inventory, 'cost'));
    set(inventoryTransaction, 'changeType', changeType);
    set(inventoryTransaction, 'createdAt', createdAt);
    set(inventoryTransaction, 'inventory', inventory);
    set(inventoryTransaction, 'isAdjustment', isAdjustment);
    set(inventoryTransaction, 'receiveType', receiveType);

    switch (changeType) {
      case 'Less':
        set(
          inventoryTransaction,
          'quantity',
          get(inventoryTransaction, 'quantity') * -1
        );
        break;

      case 'Add':
        set(
          inventoryTransaction,
          'quantityRemaining',
          parseInt(get(inventoryTransaction, 'quantity'))
        );
        break;
    }

    return inventoryTransaction;
  }

  @action
  saveInventory(shouldTransition = true) {
    this.inventory
      .save()
      .then(() => {
        this.flashes.addSuccess(
          `${this.inventory.get('name')} has been saved!`
        );
  
        if (shouldTransition) {
          this.transitionToRoute('authenticated.inventory.index');
        }
      })
      .catch(() => {
        this.flashes.addError(
          `Uh oh... There was a problem saving ${this.inventory.get('name')}!`
        );
      });
  }

  @action
  close() {
      this.newInventoryTransaction.rollbackAttributes();
      this.inventory.rollbackAttributes();

      this.showValidation = false;

      this.transitionToRoute('authenticated.inventory.index');
  }

  @action
  submitInventoryChange(changeType, isAdjustment) {
      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          this.showValidation = false;

          const inventory = this.inventory;
          const createdAt = moment();
          const inventoryTransaction = this.newInventoryTransaction;
          const receiveType = isAdjustment ? null : this.receiveType;

          this.createInventoryTransaction(
            inventoryTransaction,
            changeType,
            createdAt,
            inventory,
            isAdjustment,
            receiveType
          );

          inventoryTransaction
            .save()
            .then(transaction => {
              let amount = null;

              if (isAdjustment && !this.inventory.get('initialQuantitySet')) {
                amount = parseInt(get(transaction, 'quantity'));
                set(
                  inventory,
                  'beginningBalanceQuantity',
                  get(transaction, 'quantity')
                );
                set(inventory, 'beginningBalanceDate', createdAt);
                set(inventory, 'initialQuantitySet', true);
              } else {
                if (changeType === 'Less') {
                  amount =
                    parseInt(get(inventory, 'quantity')) -
                    parseInt(get(transaction, 'quantity'));
                } else {
                  amount =
                    parseInt(get(inventory, 'quantity')) +
                    parseInt(get(transaction, 'quantity'));
                }
              }

              set(inventory, 'quantity', amount);

              inventory
                .save()
                .then(() => {
                  inventoryTransaction.rollbackAttributes();
                  this.send('flashSuccess', 'Product successfully updated!');
                  set(
                    this,
                    'newInventoryTransaction',
                    this.store.createRecord('inventory-transaction', {
                      inventory
                    })
                  );
                  this.send('refreshModel');
                })
                .catch(() => {
                  this.send('flashError', 'Failed to update product quantity!');
                });
            })
            .catch((e) => {
              const cantAdjustInventory = e?.errors?.firstObject === 'Current user does not have the permission to adjust inventory';
              const err = cantAdjustInventory ? this.intl.t('authenticated.inventory.edit.error.permissions') : this.intl.t('authenticated.inventory.edit.error.save');
              this.send('flashError', err);
            });
        } else {
          this.showValidation = true;
        }
      });
  }

  @action
  toggleArchiveInventory(inventory) {
    inventory.set('archivedAt', inventory.get('archivedAt') ? null : moment());
    inventory.save();
    this.showDialog = false;
    this.flashes.addSuccess(`${inventory.get('name')} has been updated!`);
  }
}
