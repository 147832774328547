import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CraknClientsEditCaseSectionConfigController extends Controller {
  @service flashes;

  handleSaveSuccess() {
    this.flashes.addSuccess('Client saved!');
  }

  handleSaveError() {
    this.flashes.addError('Oops! There was an error saving this client.');
  }

  saveClient() {
    this.client
        .save()
        .then(() => this.handleSaveSuccess())
        .catch(() => this.handleSaveError());
  }
  
  @action
  save() {
    if (this.client.canUseSelectionValidations) {
      this.selectionValidationsConfig.save();
    }

    this.saveClient();
  }
}
