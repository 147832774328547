import { run } from '@ember/runloop';
import RSVP from 'rsvp';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import Bugsnag from '@bugsnag/js';

export default Route.extend({
  api: service(),
  session: service(),
  intl: service(),
  async model() {
    const model = this.modelFor('authenticated.kases.manage.financials');
    const location = get(model, 'kase.location');
    const gpl = await get(location, 'generalPriceList');
    const coaId = await gpl.get('chartOfAccounts.id');
    await this.store.findRecord('chartOfAccounts', coaId);

    return RSVP.hash({
      kase: get(model, 'kase'),
      interestTypes: this.store.query('v2/interestType', {
        archived: false,
        chart_of_accounts_id: coaId,
        per_page: 1000
      }),
      insurancePolicies: this.store.query('insurancePolicy', {
        kase_id: get(model, 'kase.id'),
        assigned: false
      }),
      selections: this.store.query('selection', {
        kase_id: get(model, 'kase.id'),
        per_page: 1000,
        type: 'KaseSelection'
      }),
      newPayment: this.store.createRecord('payment', {
        collectedBy: get(this, 'session.currentUser'),
        enteredBy: get(this, 'session.currentUser')
      }),
      newInterest: this.store.createRecord('interest'),
      newCollectionAttempt: this.store.createRecord('v2/collection-attempt'),
      toggleCreateInterest: false,
      card: { isProcessingCreditCard: false },
      billing: {
        first_name: '',
        last_name: '',
        country: 'US',
        isProcessingCreditCard: false
      },
      disabled: false,
      paymentSteps: {
        isProcessingCreditCard: false,
        isConfirmingPayment: false
      },
      paymentTypes: this.store.query('paymentType', {
        archived: false,
        gpl_id: get(gpl, 'id'),
        location_id: get(location, 'id'),
        per_page: 1000,
        tps_approved: true,
      }).then(results => {
        const insurancePaymentsAllowed = this.session.currentClient.can_assign_insurance_to_payments;

        if (!insurancePaymentsAllowed) {
          return results.filter(x => x.get('associateInsurancePolicy') == false);
        }

        return results;
      })
    });
  },


  redirect(model, transition) {
    if (transition.targetName === 'authenticated.kases.manage.financials.payments.index') {
      const oldestBalanceSelection = get(model, 'selections').filter(
        selection => {
          return get(selection, 'balance') > 0;
        }
      );

      this.transitionTo(
        'authenticated.kases.manage.financials.payments.edit',
        isEmpty(oldestBalanceSelection)
          ? get(model, 'selections.firstObject.id')
          : get(oldestBalanceSelection, 'firstObject.id')
      );
    }
  },

  setupController(controller, models) {
    this._super(...arguments);
    controller.setProperties(models);
    controller.setProperties({
      isPaymentDisabled:false,
      disablePaymentLabel: null
    });
  },

  renderTemplate(controller, models) {
    this._super(...arguments);
    set(models, 'newPayment.person', get(controller, 'selection.purchaser'));
  },

  handlePaymentSaveSuccess() {
    const selection = get(this, 'controller.selection');

    selection
      .save()
      .then(() => {
        set(
          this,
          'currentModel.newPayment',
          this.store.createRecord('payment', {
            collectedBy: get(this, 'session.currentUser'),
            enteredBy: get(this, 'session.currentUser')
          })
        );
        this.send('refreshKaseFinancial');
        this.send('flashSuccess', 'Payment successfully saved!');
        set(this.controller, 'isPaymentDisabled', false);
        this.refresh();
      })
      .catch((error) => {
        Bugsnag.notify(error, function (event) {
          event.addMetadata('tempmeta', { source: 'handlePaymentSaveSuccess()' })
        })
        run.bind(this, 'handleError')
      });
  },

  handlePaymentRemoveSuccess() {
    this.refresh();
    this.send('refreshKaseFinancial');
    set(this.controller, 'isPaymentDisabled', false);
    this.send('flashSuccess', 'Payment successfully saved!');
  },

  handleError() {
    this.send(
      'flashError',
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
    );
    set(this.controller, 'isPaymentDisabled', false);
  },

  validBilling(billing) {
    const keys = {
      first_name: 'First name',
      last_name: 'Last name'
    };
    return this.validateObjectKeys(billing, keys);
  },

  validCard(card) {
    const keys = {
      cc_number: 'Credit card number',
      cc_name: 'Credit card name',
      cc_exp: 'Expiration',
      cvv: 'cvv'
    };
    return this.validateObjectKeys(card, keys);
  },

  validateObjectKeys(object, jsonData) {
    const validation = { value: true, errors: [] };
    Object.keys(jsonData).forEach(key => {
      const appearence = object[key];
      const result =
        appearence != undefined && appearence != null && appearence != '';
      validation['value'] = validation['value'] && result;
      if (!result) {
        validation['errors'].push(`${jsonData[key]} can't be blank`);
      }
    });
    return validation;
  },

  joinErrorsToSentence(array) {
    return `${array.slice(0, array.length - 1).join(', ')}, and ${array.slice(
      -1
    )}`;
  },

  saveInterest(payment) {
    if (this.session.currentClient.can_view_interests && this.controller.toggleCreateInterest) {
      const interest = this.currentModel.newInterest;
      interest.selection = this.controller.selection;
      interest.payment = payment;
      return interest.save();
    }
  },

  actions: {
    // Selection defined in a nested route but payments are created here
    // ** Send action is in payments/edit setupController **
    setSelection(selection) {
      set(this, 'controller.selection_id', get(selection, 'id'));
    },

    // Selection defined in a nested route but payments are created here
    // ** Send action is in payments/edit setupController **
    setDefaultPaymentPurchaser(person) {
      const payment = this.currentModel.newPayment;
      if (get(payment, 'isNew')) {
        set(payment, 'person', person);
      }
    },

    updateCurrentSelection(selectionId) {
      const selection = selectionId
        ? get(this, 'currentModel.selections').findBy('id', selectionId)
        : null;
      this.transitionTo(
        'authenticated.kases.manage.financials.payments.edit',
        get(selection, 'id')
      );
    },

    cancelNewPayment() {
      if (confirm('This payment will be cancelled. Are you sure?')) {
        set(this.controller, 'showValidation', false);
        this.refresh();
      }
    },

    saveNewPayment(newPayment) {
      const selection = get(this.controller, 'selection');

      if (get(this, 'currentModel.isPaymentDisabled') === true) {
        return;
      } else {
        const personName = newPayment.get('person.name');
        const payer = newPayment.get('payer');

        if (personName && !payer) {
          set(newPayment, 'payer', personName);
        }

        set(this.controller, 'isPaymentDisabled', true);
        selection.get('payments').pushObject(newPayment);

        newPayment.setProperties({
          payerAltSrc: this.controller.altPayer
        });

        if (newPayment.payerAltSrc == true && newPayment.get('person')) {
          set(newPayment, 'person', null);
        }
        newPayment
          .save()
          .then((payment) => {
            if (payment.stripeCheckoutUrl) {
              window.location.replace(payment.stripeCheckoutUrl);
            }
            this.saveInterest(payment);
            this.handlePaymentSaveSuccess();
          })
          .catch((error) => {
            this.handleError();
            selection.get('payments').removeObject(newPayment);
            Bugsnag.notify(error, function (event) {
              event.addMetadata('tempmeta', { source: 'saveNewPayment()' })
            })
            run.bind(this, 'handleError')
          });
      }
    },

    removePayment(payment) {
      payment
        .destroyRecord()
        .then(
          run.bind(this, 'handlePaymentRemoveSuccess'),
        )
        .catch((error) => {
          Bugsnag.notify(error, function (event) {
            event.addMetadata('tempmeta', { source: 'removePayment()' })
          })
          run.bind(this, 'handleError')
        });
    },

    savePerson() {
      const newPayment = this.get('controller.newPayment');
      return get(newPayment, 'person.content').save();
    },

    authorizePayment(newPayment, billing) {
      const location = this.currentModel.kase.location
      const card = this.currentModel.card;
      const lastFour = card.cc_number.slice(-4);
      const selection = get(this.controller, 'selection');
      set(newPayment, 'selection', selection);
      set(newPayment, 'ccLastFour', lastFour);
      const billingValidness = this.validBilling(billing);
      const cardValidness = this.validCard(card);
      const personName = newPayment.get('person.name');
      const payer = newPayment.get('payer');
      if (personName && !payer) {
        set(newPayment, 'payer', personName);
      }

      if (billingValidness['value']) {
        if (cardValidness['value']) {
          const expires = get(card, 'cc_exp');
          set(card, 'cc_exp', expires.replace(/ /g, ''));

          set(this, 'currentModel.paymentSteps.isConfirmingPayment', true);
          const enteredNotes = get(newPayment, 'notes');
          const purchasersName = get(card, 'cc_name');
          if (enteredNotes == null) {
            set(newPayment, 'notes', purchasersName);
          } else {
            set(newPayment, 'notes', `${purchasersName} - ${enteredNotes}`);
          }

          // Remove FE properties for validation before making request
          delete billing.isProcessingCreditCard;
          delete card.isProcessingCreditCard;

          this.api.json.post(
            'payments/authorize_payment',
            {
              body: {
                billing: JSON.stringify(billing),
                amount: newPayment.get('amount'),
                kase_id: this.currentModel.kase.id,
                selection_id: get(selection, 'id'),
                location_id: get(location, 'id')
              }
            }
          ).then(response => {
            if (response.ok) {
              const form = document.querySelector('#credit-card-form');
              const tnResponse = JSON.parse(response.parsedJson.response);
              set(newPayment, 'transactionId', tnResponse.transaction_id);
              set(newPayment, 'processorPending', true);

              newPayment.setProperties({
                name: purchasersName,
                line1: billing.address1,
                line2: billing.address2,
                city: billing.city,
                state: billing.state,
                zipCode: billing.postal,
                country: billing.country
              });

              newPayment
                .save()
                .then((payment) => {
                  this.saveInterest(payment);
                  this.handlePaymentSaveSuccess();
                })
                .catch((error) => {
                  Bugsnag.notify(error, function (event) {
                    event.addMetadata('tempmeta', { source: 'authorizePayment(); OK response' })
                  })
                  run.bind(this, 'handleError')
                });

              form.action = tnResponse.form_url;
              form.submit();
            } else {
              Bugsnag.notify(response.parsedJson, function (event) {
                event.addMetadata('tempmeta', { source: 'authorizePayment(); bad response' })
              });
              this.handleError();
              set(this, 'currentModel.paymentSteps.isConfirmingPayment', false);
            }
          });
        } else {
          this.send(
            'flashError',
            this.joinErrorsToSentence(cardValidness['errors'])
          );
        }
      } else {
        this.send(
          'flashError',
          this.joinErrorsToSentence(billingValidness['errors'])
        );
      }
    },

    cancelPayment() {
      const newPayment = this.currentModel.newPayment;
      if (newPayment.get('hasDirtyAttributes')) {
        if (confirm('This payment will be cancelled. Are you sure?')) {
          newPayment.rollbackAttributes();
          this.refresh();
        }
      }
    }
  }
});
