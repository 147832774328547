import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class ClientConfigService extends Service {
  @service intl;

  categories = [
    'All',
    'Admin Navigation',
    'Case',
    'Case Financial',
    'Event',
    'Global Navigation',
    'Integrations',
    'Product and Inventory'
  ];

  adminNavigationFeatures = [
    {
      category: 'Admin Navigation',
      labelText: 'Can View Users?',
      value: 'canViewUsers'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Groups?',
      value: 'canViewGroups'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Online Planner?',
      value: 'canViewOnlinePlanner'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Organizations?',
      value: 'canViewOrganizations'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Locations?',
      value: 'canViewLocations'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Financial Configurations?',
      value: 'canViewFinancialConfigurations',
      conditionalElements: [
        {
          component: 'g/tms/financial-configuration-checkboxes'
        }
      ]
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View General Price Lists?',
      value: 'canViewGeneralPriceLists'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Admin Forms?',
      value: 'canViewFormsAdmin'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Data?',
      value: 'canViewData'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Info?',
      value: 'canViewInfo'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Audit Logs?',
      value: 'canViewAuditLogs'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Tribute Video Admin?',
      value: 'canViewTributeVideoAdmin'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Custom Field Configurations?',
      value: 'canViewCustomFieldsConfig'
    },
    {
      category: 'Admin Navigation',
      labelText: 'Can View Poems & Prayers?',
      value: 'canViewPoems'
    },
    {
      category: 'Admin Navigation',
      labelText: this.intl.t('services.client-config.checkboxes.canViewTributePay'),
      value: 'canViewTributePay',
      conditionalFeatures: [
        {
          labelText: this.intl.t('services.client-config.checkboxes.canViewSmsPaymentLink'),
          value: 'canViewSmsPaymentLink'
        }
      ]
    }
  ]

  caseFeatures = [
    {
      category: 'Case',
      labelText: 'Enable Task List',
      value: 'canViewTasks'
    },
    {
      category: 'Case',
      labelText: 'Enable Task List Admin',
      value: 'canViewTasksAdmin'
    },
    {
      category: 'Case',
      labelText: 'Can View Obituary Generator?',
      value: 'canViewObitGenerator'
    },
    {
      category: 'Case',
      labelText: 'Can View Contact Manager?',
      value: 'canViewContactManager'
    },
    {
      category: 'Case',
      labelText: 'Can View eSignature?',
      value: 'canViewEsignature'
    },
    {
      category: 'Case',
      labelText: 'Block Esignature for Unposted Contracts?',
      value: 'blockEsignForUnpostedContracts'
    },
    {
      category: 'Case',
      labelText: 'Is eSign Field Test?',
      value: 'isEsignFieldTest'
    },
    {
      category: 'Case',
      labelText: 'Has Auto Case Numbering?',
      value: 'autoKaseNumbering',
      conditionalFeatures: [
        {
          labelText: 'Force Automatic Case Numbers?',
          value: 'forceAutoKaseNumbering'
        }
      ]
    },
    {
      category: 'Case',
      labelText: 'Can View Case Tags?',
      value: 'canViewKaseTags',
      conditionalFeatures: [
        {
          labelText: 'Can Manage Case Tags?',
          value: 'canViewKaseTagsAdmin'
        },
        {
          labelText: 'One Case Tag Per Case?',
          value: 'canUseSingleKaseTag'
        }
      ]
    },
    {
      category: 'Case',
      labelText: 'Users Can Set Case Status Filter?',
      value: 'canSetKaseStatusFilter'
    },
    {
      category: 'Case',
      labelText: 'Can View Digital Signature?',
      value: 'canViewDigitalSignatures'
    },
    {
      category: 'Case',
      labelText: 'Can View Disposition Icon on Cases?',
      value: 'canViewCaseDispositionIcon'
    },
    {
      category: 'Case',
      labelText: 'Can View Preneed Categories?',
      value: 'canViewPreneedCategories'
    },
    {
      category: 'Case',
      labelText: 'Can View Forms?',
      value: 'canViewForms'
    },
    {
      category: 'Case',
      labelText: 'Can View Case Financial?',
      value: 'canViewCaseFinancial'
    },
    {
      category: 'Case',
      labelText: 'Enable Posting Validations?',
      value: 'canUseSelectionValidations',
      conditionalElements: [
        {
          component: 'g/tms/selection-validation-checkboxes'
        }
      ]
    },
    {
      category: 'Case',
      labelText: 'Can View Preneed?',
      value: 'canViewPreneedSection',
      conditionalFeatures: [
        {
          labelText: 'Can View Preneed Info Message?',
          value: 'canViewPreneedInfoMessage'
        }
      ]
    },
    {
      category: 'Case',
      labelText: 'Can View Transportation?',
      value: 'canViewTransportationSection'
    },
    {
      category: 'Case',
      labelText: 'Can View Preparations?',
      value: 'canViewPreparationsSection'
    },
    {
      category: 'Case',
      labelText: 'Can View Referral Info?',
      value: 'canViewReferralInfoSection'
    },
    {
      category: 'Case',
      labelText: 'Can View Service Info?',
      value: 'canViewServiceInfoSection'
    },
    {
      category: 'Case',
      labelText: 'Can View Case Inquiries?',
      value: 'canViewInquiriesSection'
    },
    {
      category: 'Case',
      labelText: 'Can View Documents?',
      value: 'canViewDocumentsSection'
    },
    {
      category: 'Case',
      labelText: 'Can View Printables?',
      value: 'canViewPrintablesSection',
      conditionalFeatures: [
        {
          labelText: 'Can view MIMs stationary?',
          value: 'canViewMimsStationary'
        }
      ]
    },
    {
      category: 'Case',
      labelText: 'Can View Obituary?',
      value: 'canViewObituarySection'
    },
    {
      category: 'Case',
      labelText: 'Can View Family Portal?',
      value: 'canViewFamilyPortal',
      conditionalFeatures: [
        {
          labelText: 'Automatically Send Family Portal Invite Email?',
          value: 'sendFamilyPortalEmailInvite'
        }
      ]
    },
    {
      category: 'Case',
      labelText: 'Can View Chain of Custody?',
      value: 'canViewChainOfCustody'
    },
    {
      category: 'Case',
      labelText: 'Can View Cremation Authorization?',
      value: 'canViewCremationAuthorizationSection',
      conditionalElements: [
        {
          component: 'g/tms/cremation-header-select'
        }
      ]
    },
    {
      category: 'Case',
      labelText: 'Can View Links?',
      value: 'canViewKaseLinks'
    },
    {
      category: 'Case',
      labelText: 'Can View Disposition?',
      value: 'canViewDisposition'
    },
    {
      category: 'Case',
      labelText: 'Can View Family & Friends?',
      value: 'canViewFamilyFriends'
    },
    {
      category: 'Case',
      labelText: 'Can View Veteran Info?',
      value: 'canViewVeteranInfoSection'
    },
    {
      category: 'Case',
      labelText: 'Can View Events?',
      value: 'canViewEvents'
    },
    {
      category: 'Case',
      labelText: 'Can View Custom Fields?',
      value: 'canViewCustomCaseFields'
    },
    {
      category: 'Case',
      labelText: 'Can View Is COVID-19 Case?',
      value: 'canViewIsCovid19Case'
    },
    {
      category: 'Case',
      labelText: this.intl.t('services.client-config.checkboxes.onlyFuneralDirectorsCanBeLeadStaffMembers'),
      value: 'onlyFuneralDirectorsCanBeLeadStaffMembers'
    }
  ];

  caseFinancialFeatures = [
    {
      category: 'Case Financial',
      labelText: 'Can Lock Posting Periods?',
      value: 'canLockPostingPeriods',
      conditionalFeatures: [
        {
          labelText: 'Enforce Posting Period Locking?',
          value: 'enforcePostingPeriodLocks'
        }
      ]
    },
    {
      category: 'Case Financial',
      labelText: this.intl.t('services.client-config.checkboxes.canMarkAsBadDebt'),
      value: 'canMarkBadDebt'
    },
    {
      category: 'Case Financial',
      labelText: 'Can View Special Tax?',
      value: 'canViewSpecialTaxes'
    },
    {
      category: 'Case Financial',
      labelText: 'Can View Discounts?',
      value: 'canViewDiscounts'
    },
    {
      category: 'Case Financial',
      labelText: 'Can View Interests?',
      value: 'canViewInterests'
    },
    {
      category: 'Case Financial',
      labelText: 'Can Assign Insurance Policies to Payments?',
      value: 'canAssignInsuranceToPayments'
    },
    {
      category: 'Case Financial',
      labelText: 'Can View Line Item Adjustments?',
      value: 'canViewLineItemAdjustments'
    },
    {
      category: 'Case Financial',
      labelText: 'Can View Misc Sales?',
      value: 'canViewMiscSales'
    },
    {
      category: 'Case Financial',
      labelText: 'Can View Do Not Export?',
      value: 'canViewDoNotExport'
    },
    {
      category: 'Case Financial',
      labelText: 'Default Due Date?',
      value: 'defaults_due_date'
    },
    {
      category: 'Case Financial',
      labelText: 'If No Events, Contract Date Is Set To Posted Date?',
      value: 'setContractDateToPostedDateIfNoEvents'
    },
    {
      category: 'Case Financial',
      labelText: 'Can View Generate SFGS Button?',
      value: 'canViewGenerateSfgs'
    },
    {
      category: 'Case Financial',
      labelText: 'Relabel Contract to Invoice?',
      value: 'relabelContractToInvoice'
    },
    {
      category: 'Case Financial',
      labelText: 'Can View Batch Payments?',
      value: 'canViewBatchPayments',
      conditionalFeatures: [
        {
          labelText: this.intl.t('services.client-config.checkboxes.canViewGeneralPayments'),
          value: 'canViewGeneralPayments'
        }
      ]
    },
    {
      category: 'Case Financial',
      labelText: 'Can View Adjustment Gains?',
      value: 'canViewAdjustmentGains'
    },
    {
      category: 'Case Financial',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.includePostingDateInFinancialDetailsExport.label'),
      value: 'includePostingDateInFinancialDetailsExport'
    },
    {
      category: 'Case Financial',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.requireReasonForUnposting.label'),
      value: 'requireReasonForUnposting'
    },
    {
      category: 'Case Financial',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.canViewCustomSelectionSorting.label'),
      value: 'canViewCustomSelectionSorting'
    },
    {
      category: 'Case Financial',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.canViewStatementCategories.label'),
      value: 'canViewStatementCategories'
    },
    {
      category: 'Case Financial',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.canSeparateTaxCalculations.label'),
      value: 'canSeparateTaxCalculations'
    }
  ];

  eventFeatures = [
    {
      category: 'Event',
      labelText: 'Can View Family Arrival Time?',
      value: 'canViewFamilyArrivalTime'
    },
    {
      category: 'Event',
      labelText: 'Can View Employee Arrival Time?',
      value: 'canViewEmployeeArrivalTime'
    },
    {
      category: 'Event',
      labelText: 'Can View Vehicles?',
      value: 'canViewVehicles'
    },
    {
      category: 'Event',
      labelText: 'Event Staff Member Notification Accept / Decline?',
      value: 'eventStaffMemberInvites',
      conditionalFeatures: [
        {
          labelText: 'Send Responses to Case Lead',
          value: 'eventStaffMemberLeadResponse'
        },
        {
          labelText: 'Send Responses to Original Assigner',
          value: 'eventStaffMemberAssigneeResponse'
        }
      ]
    }
  ];

  globalNavigationFeatures = [
    {
      category: 'Global Navigation',
      labelText: 'Can View Cases?',
      value: 'canViewKases'
    },
    {
      category: 'Global Navigation',
      labelText: 'Can View Contacts?',
      value: 'canViewContacts'
    },
    {
      category: 'Global Navigation',
      labelText: 'Can View Accounting?',
      value: 'canViewAccounting',
    },
    {
      category: 'Global Navigation',
      labelText: 'Can View Whiteboard?',
      value: 'canViewWhiteboard'
    },
    {
      category: 'Global Navigation',
      labelText: 'Can View Calendar?',
      value: 'canViewCalendar'
    },
    {
      category: 'Global Navigation',
      labelText: 'Can View Places?',
      value: 'canViewPlaces'
    },
    {
      category: 'Global Navigation',
      labelText: 'Can View Reports?',
      value: 'canViewReports'
    },
    {
      category: 'Global Navigation',
      labelText: 'Can View Inquiries?',
      value: 'canViewInquiriesGlobal'
    },
    {
      category: 'Global Navigation',
      labelText: 'Can View Financial Detail?',
      value: 'canViewFinancialDetail'
    }
  ]

  integrationFeatures = [
    {
      category: 'Integrations',
      labelText: 'Can View CemSites?',
      value: 'canViewCemsites'
    },
    {
      category: 'Integrations',
      labelText: 'Can View Ohio EDRS?',
      value: 'canViewOhioEdrs'
    },
    {
      category: 'Integrations',
      labelText: 'Can View Growth Engine?',
      value: 'canViewGrowthEngine'
    },
    {
      category: 'Integrations',
      labelText: this.intl.t('services.client-config.checkboxes.canHideDeathNotice'),
      value: 'canHideDeathNotice'
    }
  ];

  productInventoryFeatures = [
    {
      category: 'Product and Inventory',
      labelText: 'Can View Additional Product Info?',
      value: 'canViewAdditionalProductInfo'
    },
    {
      category: 'Product and Inventory',
      labelText: 'Can View Inventory?',
      value: 'canViewInventory',
      conditionalFeatures: [
        {
          labelText: 'Can View Pull From Inventory?',
          value: 'canViewPullFromInventory'
        },
        {
          labelText: 'Can View Pull From Beaconfp Inventory?',
          value: 'canViewPullFromBeaconfpInventory'
        }
      ]
    },
    {
      category: 'Product and Inventory',
      labelText: 'Can View Vendors?',
      value: 'canViewVendors'
    },
    {
      category: 'Product and Inventory',
      labelText: 'Can View Price List Dates?',
      value: 'canViewPriceListDates'
    },
    {
      category: 'Product and Inventory',
      labelText: 'Can View COGS on Custom Products?',
      value: 'canViewCogsCustomProducts'
    },
    {
      category: 'Product and Inventory',
      labelText: 'Use Custom Products?',
      value: 'hasCustomProducts'
    },
    {
      category: 'Product and Inventory',
      labelText: 'Has Discretionary Items?',
      value: 'hasDiscretionaryItems'
    },
    {
      category: 'Product and Inventory',
      labelText: 'Can View Flexible Item(s) Package Slots?',
      value: 'canViewPackageSlotFlexibleItems'
    }
  ];

  allFeatures = [
    ...this.adminNavigationFeatures,
    ...this.caseFeatures,
    ...this.caseFinancialFeatures,
    ...this.eventFeatures,
    ...this.globalNavigationFeatures,
    ...this.integrationFeatures,
    ...this.productInventoryFeatures
  ];

  @tracked features = this.allFeatures;
  @tracked currentFilter = 'All';

  filterFeatures(categoryType) {
    this.currentFilter = categoryType;

    if (categoryType == 'All') {
      this.features = this.allFeatures;
    } else {
      this.features = this.allFeatures.filter(
        (feature) => feature.category === categoryType
      );
    }
  }

  searchFeatures(value) {
    if (this.currentFilter !== 'All') {
      this.currentFilter = 'All';
    }

    this.features = this.allFeatures.filter((feature) =>
      feature.labelText.toLowerCase().includes(value.toLowerCase())
    );
  }
}
