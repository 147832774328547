import { helper as buildHelper } from '@ember/component/helper';

export function tcoCommentVideoLink(args) {
  if (!args && !args[0]){
    return '';
  }

  const media = args[0].Media;

  if (!media) {
    return '';
  }

  const found = media.Attributes?.find(x => x.ContentType === 'video/mp4');
  
  if (!found) {
    return '';
  }

  return `https:${found.Url}`;
}

export default buildHelper(tcoCommentVideoLink);
