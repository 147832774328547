import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknFormsTfeEditRoute extends Route {
  @service store;

  async model(params) {
    const form = await this.store.findRecord('tfe/document', params.document_id, { include: 'latest_revision', reload: true });
    const enabledDocumentClients = this.store.query('tfe/client', { document_id: params.document_id })
    return { form, enabledDocumentClients }
  }

  async setupController(controller, model, _transition) {
    super.setupController(...arguments);

    controller.setProperties({
      documentFile: null,
      enabledDocumentClients: model.enabledDocumentClients,
      form: model.form,
    });
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.setProperties({
        documentFile: null,
        enabledDocumentClients: null,
        form: null,
        latestRevision: null,
        originalMetadata: null,
        pollingDelay: 3000,
        revisionPollAttempts: 10
      });
    }
  }

  @action 
  willTransition(_transition) {
    if (this.currentModel.form.hasDirtyAttributes) {
      this.currentModel.form.rollbackAttributes();
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
