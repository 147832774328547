import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesManageIntegrationsTcoV2Route extends Route {
  @service api;
  @service session;
  @service store;

  layout = 'custom-aside';

  async model() {
    const kase = await this.modelFor('authenticated.kases.manage.integrations');
    const deceased = await kase.get('deceased');
    const canExport = !(
      this.missingAttr(deceased, 'firstName') ||
      this.missingAttr(deceased, 'lastName') ||
      this.missingAttr(deceased, 'deathDate')
    );

    if (!kase.tcoId && canExport) {
      const mapping = await this.api.json.get(`tco/kases/${kase.get('id')}/external_mapping`);
      const id = mapping.parsedJson['EntityId'];

      if (mapping.ok && id) {
        kase.tcoId = id;
      }
    }

    const tcoKaseIsExported = Boolean(kase.tcoId);

    let isPublishedState;
    let legacyTouchPins;
    let tcoKase;
    let tcoKaseExtended;
    let tcoKaseInfo;
    let wallSettings;

    if (canExport && tcoKaseIsExported) {
      const resp = await this.api.json.get(`tco/kases/${kase.get('id')}/external_case`);

      if (resp.ok) {
        tcoKase = resp.parsedJson;
        isPublishedState = resp.parsedJson.IsPublished;

        const extResp = await this.api.json.get(`tco/kases/${kase.get('id')}`);

        if (extResp.ok) {
          tcoKaseExtended = extResp.parsedJson;
          tcoKase.HideBirthdate = tcoKaseExtended.HideBirthDate
          tcoKase.HideDeathDate = tcoKaseExtended.HideDeathDate
          tcoKase.PrivateGuestBook = tcoKaseExtended.Settings.PrivatizeGuestBook
        }

        const infoResponse = await this.api.json.get(`tco/kases/${kase.get('id')}/obituary_info`);

        if (infoResponse.ok) {
          tcoKaseInfo = infoResponse.parsedJson;
        }

        const settingsResp = await this.api.json.get(`tco/kases/${kase.get('id')}/settings`);

        if (settingsResp.ok) {
          wallSettings = settingsResp.parsedJson;

          if (wallSettings?.EnableLegacyTouch) {
            const legacyResp = await this.api.json.get(`tco/kases/${kase.get('id')}/legacy_touch`);

            legacyTouchPins = legacyResp.parsedJson;
          }
        }
      }
    }

    return {
      integrationService: await this.store.queryRecord('integrationService', {
        client_id: this.session.get('currentClient.id'),
        name: 44, // tco-v2
        not_category: ['accounting', 'answering_service'],
        per_page: 1000,
      }),
      isPublishedState,
      kase,
      legacyTouchPins,
      tcoKase,
      tcoKaseExtended,
      tcoKaseInfo,
      wallSettings,
    }
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    const tcoExport = await this.store.query('v2/kaseIntegration', {
      kase_id: model.kase.get('id'),
      integration_service_id: model.integrationService.get('id'),
      status: 'successful'
    });

    controller.set('tcoExport', tcoExport.firstObject);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.setProperties({
        fbTimer: null,
        fbWindow: null,
        postToFacebook: true,
      })
    }
  }

  missingAttr(deceased, attribute) {
    const attr = deceased.get(attribute);

    if (attribute === 'deathDate') {
      return attr && attr?.isValid() ? false : true;
    }

    return !attr;
  }
}
