import Model, { hasMany, attr } from '@ember-data/model';
import { notEmpty } from '@ember/object/computed';
import { fullName, phoneNumber } from 'crakn/utils/people-helpers';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  firstName: validator('presence', {
    message: 'First or Last Name must be provided.',
    presence: true,
    dependentKeys: ['model.lastName'],
    disabled: notEmpty('model.lastName'),
  }),
  lastName: validator('presence', {
    message: 'First or Last Name must be provided.',
    presence: true,
    dependentKeys: ['model.firstName'],
    disabled: notEmpty('model.firstName'),
  }),
});

export default class PersonModel extends Model.extend(Validations) {
  @attr('boolean') retired;
  @attr('string', { defaultValue: 'Unknown' }) gender;
  @attr('string', { defaultValue: 'US' }) birthplaceAddressCountry;
  @attr('string') country;
  @attr('string', { defaultValue: 'US' }) mailingAddressCountry;
  @attr('string') interestsOrAchievements;
  @attr('string') addressLine1;
  @attr('string') addressLine2;
  @attr('string') affiliation;
  @attr('string') aka;
  @attr('string') birthplaceAddressCity;
  @attr('string') birthplaceAddressCounty;
  @attr('string') birthplaceAddressLine1;
  @attr('string') birthplaceAddressLine2;
  @attr('string') birthplaceAddressState;
  @attr('string') birthplaceAddressTownship;
  @attr('string') birthplaceAddressZipCode;
  @attr('string') borough;
  @attr('string') church;
  @attr('string') city;
  @attr('string') contactAge;
  @attr('string') county;
  @attr('string') education;
  @attr('string') email;
  @attr('string') emailSecondary;
  @attr('string') employer;
  @attr('string') employerYears;
  @attr('string') firstName;
  @attr('string') hispanicOrigin;
  @attr('string') industry;
  @attr('string') jewishName;
  @attr('string') kaseAssociations;
  @attr('string') kaseId;
  @attr('string') language;
  @attr('string') lastName;
  @attr('string') licenseNumber;
  @attr('string') maidenName;
  @attr('string') mailingAddressCity;
  @attr('string') mailingAddressCounty;
  @attr('string') mailingAddressLine1;
  @attr('string') mailingAddressLine2;
  @attr('string') mailingAddressState;
  @attr('string') mailingAddressZipCode;
  @attr('string') maritalStatus;
  @attr('string') middleName;
  @attr('string') namePrefix;
  @attr('string') nameSuffix;
  @attr('string') netsuiteId;
  @attr('string') nickname;
  @attr('string') notes;
  @attr('string') occupation;
  @attr('string') organizationsOrMemberships;
  @attr('string') printedName;
  @attr('string') race;
  @attr('string') raceOther;
  @attr('string') religion;
  @attr('string') spouseFirstName;
  @attr('string') spouseLastName;
  @attr('string') spouseMaidenName;
  @attr('string') spouseMiddleName;
  @attr('string') ssn;
  @attr('string') ssnLastFour;
  @attr('string') state;
  @attr('string') township;
  @attr('string') zipCode;

  @attr('boolean') deceased;
  @attr('boolean') hasExistingKase;
  @attr('boolean') hispanic;
  @attr('boolean') insideCityLimits;
  @attr('phone') faxNumber;
  @attr('phone') phonePrimary;
  @attr('phone') phoneSecondary;

  @attr('moment-datetime') archivedAt;
  @attr('moment-date') birthDate;
  @attr('moment-date') deathDate;
  @attr('moment-date') retirementDate;

  @attr('number') yearsInCounty;
  @attr('number') yearsInOccupation;

  @hasMany('relative', { inverse: 'decedent', async: true }) relationshipsThroughDeceased;
  @hasMany('relative', { inverse: 'person', async: true }) relationshipsThroughPeople;

  @notEmpty('addressLine1', 'city', 'state', 'zipcode', 'country') addressCheck;

  get contactAddress() {
    if (!this.addressLine1 && !this.addressLine2) {
      return null;
    }

    return [this.addressLine1, this.addressLine2].filter(Boolean).join(', ');
  }

  get contactCityState() {
    const cityState = [this.city, this.state].filter(Boolean).join(', ');

    if (!this.state) {
      return [cityState, this.zipCode].filter(Boolean).join(', ');
    } else {
      return [cityState, this.zipCode].filter(Boolean).join(' ');
    }
  }

  get singleLineAddress() {
    return [ this.addressLine1, this.addressLine2, this.city, this.state, this.zipCode,].filter(Boolean).join('+');
  }

  get name() {
    return fullName(this);
  }

  set name(value) {
    const names = value.split(' ');
    const [firstName] = names;
    const lastName = names.slice(1).join(' ');

    this.firstName = firstName;
    this.lastName = lastName;
    this.namePrefix = null;
    this.middleName = null;
    this.maidenName = null;
    this.nameSuffix = null;

    return value;
  }

  get nameNoMaiden() {
    return fullName(this, '', false);
  }

  get fullNameWithMiddle() {
    return fullName(this, '', true, true);
  }

  get displayPhoneNumber() {
    return phoneNumber(this);
  }

  get typeaheadName() {
    return fullName(this) + (this.archivedAt ? ' (archived)' : '');
  }
}
