import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default Component.extend({
  person: null,
  session: service(),
  api: service(),

  display: computed('person.ssn', 'paddedLastFour', {
    get() {
      return get(this, 'person.ssn') || this.paddedLastFour;
    },

    set(key, value) {
      value = isEmpty(value) ? null : value;
      set(this, 'person.ssnLastFour', null);
      set(this, 'person.ssn', value);
      return value;
    }
  }),

  disabled: computed(
    'canReveal',
    'session.currentUser.permissions',
    function() {
      if (get(this, 'session.currentUser.permissions') <= 1) {
        return true;
      } else if (this.canReveal) {
        return true;
      } else {
        return false;
      }
    }
  ),

  paddedLastFour: computed('person.ssnLastFour', function() {
    if (!get(this, 'person.ssnLastFour')) {
      return null;
    }
    return `•••-••-${get(this, 'person.ssnLastFour')}`;
  }),

  canReveal: computed(
    'person.{ssnLastFour,ssn}',
    'session.currentUser.permissions',
    function() {
      return (
        get(this, 'session.currentUser.permissions') > 1 &&
        get(this, 'person.ssnLastFour') &&
        !get(this, 'person.ssn')
      );
    }
  ),

  handleFetchSuccess(response) {
    set(this, 'person.ssn', response.ssn.full);
    set(this, 'person.ssnLastFour', '');
  },

  actions: {
    reveal() {
      const endpoint = `/people/${get(this, 'person.id')}/ssn`;

      this.api.json
          .get(endpoint)
          .then((response) => {
            if (response.ok) {
              this.handleFetchSuccess(response.parsedJson);
            }
          });
    }
  }
});
