import { readOnly, gt } from '@ember/object/computed';
import Component from '@ember/component';
import { set } from '@ember/object';
import Toolbelt from 'crakn/mixins/component-toolbelt';

export default Component.extend(Toolbelt, {
  classNames: ['list__item--wrap'],
  classNameBindings: ['isInPackage::red'],
  tagName: 'li',
  isEditingProd: false,

  model: null,
  packageProducts: null,
  showDialog: false,

  idKey: readOnly('model.id'),

  // Determines whether or not this product appears in it's parent package
  // Note: This is only for kase selections
  isInPackage: gt('model.packages.length', 0),

  actions: {
    removeProduct() {
      this.attrs.onRemoveProduct(this.model);
    },
    saveProduct() {
      set(this, 'isEditingProd', false);
      this.attrs.saveProduct(this.model);
    },
    revertProduct() {
      set(this, 'isEditingProd', false);
      this.attrs.revertProduct(this.model);
    }
  }
});
