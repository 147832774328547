import { helper as buildHelper } from '@ember/component/helper';

export function tcoCommentVideoLink(args) {
  if (!args && !args[0] && !args[1]){
    return '';
  }

  const name = args[0];
  const chopString = args[1];

  if (!name) {
    return 'No Theme Name';
  }

  if (!chopString) {
    return name;
  }

  const index = name.indexOf(chopString);
  return index === -1 ? name : name.substring(0, index);
}

export default buildHelper(tcoCommentVideoLink);
