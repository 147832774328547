import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2PackageModel extends Model  {
  @attr('string') name;
  @attr('string') description;
  @attr('number') customOrder;
  @attr('price') pricingAmount;
  @attr('string', { defaultValue: 'normal' }) pricingMethod;

  @belongsTo('generalPriceList', { async: true }) generalPriceListId;
  @belongsTo('invoiceCategory', { async: true }) invoiceCategoryId;

  @hasMany('v2/package-slot', { async: true }) packageSlots;
  @hasMany('v2/package-slot-product', { async: true }) packageSlotProducts;
}
