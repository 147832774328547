import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const Validations = buildValidations({
  'model.name': validator('presence', true),
  'model.invoiceCategoryId': validator('presence', true),
});

export default class AuthenticatedAdminGeneralPriceListsEditPackagesNewController extends Controller.extend(Validations) {
  @service flashes;
  @service intl;
  @service router;
  @service session;

  @tracked generalPriceList; // Set in the router;
  @tracked showValidations = false;

  get productQueryParams() {
    return {
      general_price_list_id: this.generalPriceList.get('id'),
      archived: false,
    };
  }

  get isNormal() {
    return this.model.pricingMethod === 'normal';
  }

  get currencySymbol() {
    return '$';
  }

  get totalWarning() {
    return this.model.total < this.model.productsTotal;
  }

  get isValidPricingMethodAmount() {
    if (this.model.pricingMethod === 'amount') {
      return this.model.discountedTotal > 0;
    }

    if (this.model.pricingMethod === 'fixed') {
      return this.model.calculatedDiscount > 0;
    }

    return true;
  }

  get displayTotalWarningColor() {
    if (this.showValidations && !this.isValidPricingMethodAmount) {
      return 'red';
    }

    if (this.totalWarning) {
      return 'yellow';
    }

    return null;
  }

  get pricingDetailsAmountText() {
    if (this.model.pricingMethod === 'fixed') {
      return this.intl.t('authenticated.admin.general-price-lists.edit.packages.new.lists.pricingDetails.items.finalTotal.text.options.finalTotal')
    }

    return this.intl.t('authenticated.admin.general-price-lists.edit.packages.new.lists.pricingDetails.items.finalTotal.text.options.discount');
  }

  @action
  addPackageProduct(product) {
    this.model.products.addObject(product);
  }

  @action
  removePackageProduct(product) {
    this.model.products.removeObject(product);
  }

  @action
  save() {
    this.validate().then(({ validations }) => {
      if (validations.get('isValid') && this.isValidPricingMethodAmount) {
        set(this, 'showValidations', false);

        this.model.save().then(() => {
          this.flashes.addSuccess(
            this.intl.t('authenticated.admin.general-price-lists.edit.packages.new.alerts.save.success')
          );

          this.router.transitionTo('authenticated.admin.general-price-lists.edit.packages');
        }).catch(() => {
          this.flashes.addError(
            this.intl.t('authenticated.admin.general-price-lists.edit.packages.new.alerts.save.error')
          );
        })
      } else {
        set(this, 'showValidations', true);
      }
    });
  }

  @action
  cancel() {
    this.model.rollbackAttributes();
    this.router.transitionTo('authenticated.admin.general-price-lists.edit.packages');
  }
}
