import { alias } from '@ember/object/computed';
import Model, { belongsTo, attr, hasMany } from '@ember-data/model';

export default class V2KaseModel extends Model {
  @attr('string') bandName;
  @attr('string') bodyDonationNotes;
  @attr('string') casket;
  @attr('string') casketingAndCosmeticsNotes;
  @attr('string') chainOfCustodyQrCodeUrl;
  @attr('string') citizenship;
  @attr('string') clothing;
  @attr('string') customId;
  @attr('string') deathCertificateNumber;
  @attr('string') deceasedTagNumber;
  @attr('string') eiaCaseNumber;
  @attr('string') eiaCaseWorker;
  @attr('string') glasses;
  @attr('string') hairstyle;
  @attr('string') height;
  @attr('string') informantRelationship;
  @attr('string') injuryDescription;
  @attr('string') injuryLocation;
  @attr('string') injuryPlace;
  @attr('string') investigationNumber;
  @attr('string') jewelry;
  @attr('string') lipstick;
  @attr('string') nailColor;
  @attr('string') nextAvailableStatus;
  @attr('string') organDonationType;
  @attr('string') pacemakerDetail;
  @attr('string') permitTransitNumber;
  @attr('string') pickupInstructions;
  @attr('string') placeOfDeath;
  @attr('string') podAddressCity;
  @attr('string') podAddressLine1;
  @attr('string') podAddressLine2;
  @attr('string') podAddressState;
  @attr('string') podAddressZip;
  @attr('string') preneedCaseId;
  @attr('string') preneedSfgsUuid;
  @attr('string') reasonForNoEvents;
  @attr('string') reasonToEmbalm;
  @attr('string') reserveName;
  @attr('string') servicesInterestedIn;
  @attr('string') shipInFlightInformation;
  @attr('string') shipInOrganizationName;
  @attr('string') shipInTransportation;
  @attr('string') shipoutFlightInformation;
  @attr('string') shipoutTransportation;
  @attr('string') specialCircumstances;
  @attr('string') ssn;
  @attr('string') stateFileId;
  @attr('string') status;
  @attr('string') tradeCallFinancialArrangements;
  @attr('string') tradeCallNotes;
  @attr('string') tradeCallOrganizationName;
  @attr('string') tradeCallTransportNotes;
  @attr('string') tradeCallType;
  @attr('string') treatyNumber;
  @attr('string') uuid;
  @attr('string') weight;

  @attr('boolean') casketingAndCosmetics;
  @attr('boolean') declineEmbalming;
  @attr('boolean') export;
  @attr('boolean') generalPriceListLocked;
  @attr('boolean') hadAutopsy;
  @attr('boolean') hasChainOfCustody;
  @attr('boolean') hasImplantedDevices;
  @attr('boolean') hasKaseCauseOfDeath;
  @attr('boolean') hasKaseForms;
  @attr('boolean') hasNoEvents;
  @attr('boolean') hasPermissionToEmbalm;
  @attr('boolean') hideDeathNotice;
  @attr('boolean') includeInVirginiaVitalStatsReport;
  @attr('boolean') isAlcoholRelated;
  @attr('boolean') isAutopsyAfterDonation;
  @attr('boolean') isBodyDonation;
  @attr('boolean') isCoronerNotified;
  @attr('boolean') isCoronersKase;
  @attr('boolean') isExaminerInvestigatorCase;
  @attr('boolean') isFindingsAvailable;
  @attr('boolean') isHiddenFromWhiteboard;
  @attr('boolean') isInjuryRelated;
  @attr('boolean') isOrganDonor;
  @attr('boolean') isPregnant;
  @attr('boolean') isTobaccoRelated;
  @attr('boolean') isVeteran;
  @attr('boolean') isWorkInjury;
  @attr('boolean') jcgReportSendSurveyOptOut;
  @attr('boolean') locationLocked;
  @attr('boolean') permissionToTransfer;
  @attr('boolean') positivelyIdentified;
  @attr('boolean') releasedForPickup;
  @attr('boolean') shipIn;
  @attr('boolean') tradeCall;
  @attr('boolean') transportToCrematory;

  @attr('moment-date') dateCasketDelivered;
  @attr('moment-date') dateCasketOrdered;
  @attr('moment-date') hebrewDeathDate;
  @attr('moment-datetime', { defaultValue: null }) crematoryTransportTime;
  @attr('moment-datetime') embalmAuthorizationDate;
  @attr('moment-datetime') embalmingDate;
  @attr('moment-datetime') exportedAt;
  @attr('moment-datetime') identificationDate;
  @attr('moment-datetime') marriageDate;
  @attr('moment-datetime') injuryDateTime;
  @attr('moment-datetime') removalPickupTime;
  @attr('moment-datetime') shipInDateTimePickup;
  @attr('moment-datetime') shipoutDateTimePickup;

  @attr('number') tcoId;

  @attr('object') avatar;

  @attr('phone') pickupPhone;

  @belongsTo('v2/disposition', { async: true }) disposition;
  @belongsTo('v2/kase-financial', { async: true }) kaseFinancial;
  @belongsTo('v2/person', { async: true }) deceased;
  @belongsTo('v2/person', { async: true }) spouse;
  @belongsTo('v2/place', { async: false }) embalmingPlace;
  @belongsTo('v2/place', { async: false }) identificationPlace;
  @belongsTo('v2/place', { async: false }) marriagePlace;
  @belongsTo('v2/user', { async: true }) leadDirectorStaffMember;
  @belongsTo('v2/user', { async: true }) receivedByStaffMember;
  @belongsTo('v2/location', { async: true }) location;


  @hasMany('v2/custody-audit', { async: true }) custodyAudits;
  @hasMany('v2/kase-cause-of-death', { async: true }) kaseCauseOfDeath;
  @hasMany('v2/personal-effect', { async: true }) personalEffects;

  @alias('leadDirectorStaffMember') staffMember;

  get modelName() {
    return 'kases';
  }

  get hasPostedSelections() {
    const selections = this.kaseFinancial.get('selections');

    if (selections) {
      return selections.any((selection) => selection.posted);
    } else {
      return false;
    }
  }
}
