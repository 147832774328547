import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2SelectionPackageSlotModel extends Model {
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') updatedAt;

  @belongsTo('v2/package-slot', { async: true }) packageSlot;
  @belongsTo('v2/selection-package', { async: true }) selectionPackage;

  @hasMany('v2/selection-package-slot-product', { async: true }) selectionPackageSlotProducts;
}
