import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'crakn/config/environment';

export default class AuthenticatedCraknFormsTfeEditController extends Controller {
  @service api;
  @service flashes;
  @service intl;
  @service router;
  @service store;

  @tracked enabledDocumentClients = null;
  @tracked filterClient = null;
  @tracked showDialog = false;
  @tracked revisionPollAttempts = 10;
  @tracked pollingDelay = 3000;

  pollableStatuses = ['pending', 'processing'];

  get disableEsignCheckbox() {
    if (this.pollableStatuses.includes(this.form.latestRevision.status)) {
      return true;
    } else if (!this.form.latestRevision.esignable) {
      return true;
    } else {
      return false;
    }
  }

  get enabledClientNames() {
    return this.enabledDocumentClients.map((client) => client.name);
  }

  get formNameMessage() {
    if (this.form && this.form.name) {
      return null;
    } else {
      return this.intl.t(
        'authenticated.crakn.clients.edit.tfe.edit.messages.fieldValidation'
      );
    }
  }

  get documentFileMessage() {
    if (this.documentFile) {
      return null;
    } else {
      return this.intl.t('authenticated.crakn.clients.edit.tfe.new.messages.fieldValidation');
    }
  }

  get blankFormLink() {
    return `${config.host}/api/tfe/documents/${this.form.id}/download`;
  }

  get testFormLink() {
    return `${config.host}/api/tfe/documents/${this.form.id}/download_test`;
  }

  handleSaveError() {
    this.flashes.addError(
      this.intl.t('authenticated.crakn.clients.edit.tfe.edit.messages.failure')
    );
  }

  async postNewRevision() {
    const formData = new FormData();
    const latestRevision = this.form.latestRevision;

    formData.append('revision[file]', this.documentFile);
    formData.append('revision[document_id]', this.form.id);
    formData.append('revision[kase_contact_dropdown]', latestRevision.metadata.kaseContactDropdown || false);
    formData.append('revision[event_dropdown]', latestRevision.metadata.eventDropdown || false);
    formData.append('revision[location_dropdown]', latestRevision.metadata.locationDropdown || false);
    formData.append('revision[inquiry_dropdown]', latestRevision.metadata.inquiryDropdown || false);
    formData.append('revision[esign_enabled]', latestRevision.metadata.esignEnabled || false);

    if (latestRevision.clientId) {
      formData.append('revision[client_id]', latestRevision.clientId || null);
    }

    const response = await this.api.post('tfe/revisions', { body: formData });
    const json = await response.json();

    if (response.ok) {
      this.flashes.addSuccess(this.intl.t('authenticated.crakn.clients.edit.tfe.new.messages.success'));
    } else {
      let msg;

      if (json && json.errors) {
        msg = json.errors.join(', ');
      } else {
        msg = this.intl.t('authenticated.crakn.clients.edit.tfe.new.messages.failure');
      }

      this.flashes.addError(msg);
    }

    return response;
  }

  async putNewForm() {
    const documentResponse = await this.form.save();

    if (documentResponse) {
      let revisionResponse;

      if (this.documentFile) {
        revisionResponse = await this.postNewRevision();
      } else {
        revisionResponse = await this.form.latestRevision.save();
      }

      if (revisionResponse) {
        this.flashes.addSuccess(
          this.intl.t(
            'authenticated.crakn.clients.edit.tfe.edit.messages.success'
          )
        );
      } else {
        this.handleSaveError();
      }
    } else {
      this.handleSaveError();
    }
  }

  validate() {
    return !this.formNameMessage;
  }

  @action
  updateBlankForm(e) {
    e.preventDefault();

    const isValid = this.validate();
    this.showValidation = !isValid;

    if (isValid) {
      this.putNewForm();
    }
  }

  @action
  cancel() {
    this.form.latestRevision.rollbackAttributes();
    this.form.rollbackAttributes();
    this.router.transitionTo('authenticated.crakn.manage.forms.tfe.index');
  }

  @action
  typeaheadSearch(value) {
    return this.store.query('client', { can_view_form_in_tfe: 'true', query: value });
  }

  @action
  async selectClient(client) {
    if (client === undefined) return;
    if (this.enabledClientNames.includes(client.name)) return;

    const response = await this.api.json.post('tfe/documents/enable', {
        body: { client_id: client.id, document_id: this.form.id }
    });

    if (response.ok) {
      this.enabledDocumentClients = await this.store.query('tfe/client', { document_id: this.form.id })
      this.flashes.addSuccess(this.intl.t('authenticated.crakn.clients.edit.tfe.edit.addToClients.messages.success'));
    } else {
      this.flashes.addError(this.intl.t('authenticated.crakn.clients.edit.tfe.edit.addToClients.messages.failure'));
    }
  }

  @action
  async removeClient(tfeClient) {
    const response = await this.api.json.delete('tfe/documents/enable', {
        body: { tfe_client_id: tfeClient.id, document_id: this.form.id }
    });

    if (response.ok) {
      this.enabledDocumentClients = await this.store.query('tfe/client', { document_id: this.form.id })
      this.showDialog = false;
      this.flashes.addSuccess(this.intl.t('authenticated.crakn.clients.edit.tfe.edit.removeFromClient.messages.success'));
    } else {
      this.flashes.addError(this.intl.t('authenticated.crakn.clients.edit.tfe.edit.removeFromClient.messages.failure'));
    }
  }

  @action
  async downloadTestForm(e) {
    e.target.disabled = true;
    const response = await this.api.get(`tfe/documents/${this.form.id}/download_test`);

    if (response.ok) {
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.form.name + '-test';
        a.click();
      })
    } else {
      this.flashes.addError(this.intl.t('authenticated.crakn.clients.edit.tfe.edit.downloadTestForm.messages.failure'));
    }

    e.target.disabled = false;
  }

  @action
  async pollForRevision() {
    if (this.pollableStatuses?.includes(this.form.latestRevision?.status)) {
      const reloadedDocument = await this.store.findRecord('tfe/document', this.form.id, { include: 'latest_revision', reload: true });

      if (reloadedDocument && this.pollableStatuses?.includes(reloadedDocument.latestRevision?.status)) {
        this.pollingDelay += 1000;
        this.revisionPollAttempts -= 1;
        setTimeout(() => this.pollForRevision(), this.pollingDelay);
      }
    }
  }
}
