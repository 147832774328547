import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';

export default class KasesIntegrationController extends Controller {
  @service router;
  
  get isTcoRouteActive() {
    return this.router.currentRouteName === 'authenticated.kases.manage.integrations.tco';
  }

  get tcoKaseExported() {
    return this.model.tcoId && this.model.tcoId !== '';
  }

  @sort('integrationServices', function (a, b) {
    if (a.isTributeIntegration && !b.isTributeIntegration) {
      return -1;
    } else if (!a.isTributeIntegration && b.isTributeIntegration) {
      return 1;
    }

    return a.displayName.localeCompare(b.displayName);
  })
  sortedIntegrationServices;
}
