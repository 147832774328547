import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminGeneralPriceListsEditProductCategoriesCategoriesRoute extends Route {
  @service session;

  model() {
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');

    return this.store.query('v2/productCategory', {
      archived: false,
      general_price_list_id: gpl.id,
      include: [
        'account',
        'asset-account',
        'cogs-account',
        'preneed-category',
        'tag-product-categories',
        'tag-product-categories.tag',
        'tag-product-categories.account',
        'tag-product-categories.asset-account',
        'tag-product-categories.cogs-account',
        'tag-product-categories.product-category'
      ].join(','),
      per_page: 500
    });
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');
    const generalPriceList = await this.store.findRecord(
      'v2/generalPriceList',
      gpl.get('id')
    );

    let preneedCategories = [];

    if (this.session.currentClient.can_view_preneed_categories) {
      preneedCategories = await this.store.query('v2/preneedCategory', {
        archived: false,
        gpl_id: generalPriceList.id
      });
    }

    const allAccounts = await this.store.query('v2/account', {
      per_page: 5000,
      chart_of_accounts_id: generalPriceList.get('chartOfAccounts.id')
    });

    const newProductCategory = this.store.createRecord('v2/productCategory', {
      generalPriceList: generalPriceList
    });

    const tags = this.store.query('v2/tag', {
      client_id: this.session.currentClient.id,
      per_page: 500
    });

    controller.setProperties({
      allAccounts,
      generalPriceList,
      newProductCategory,
      preneedCategories,
      productCategories: model,
      tags
    })
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
