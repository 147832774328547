import Model, { attr, belongsTo } from '@ember-data/model';

export default class AccountModel extends Model {
  @attr('string') name;
  @attr('string') remoteId;
  @attr('string') accountType;
  @attr('string') generalLedgerId;
  @attr('moment-datetime', { defaultValue: null }) archivedAt;

  @belongsTo('chart-of-accounts', { inverse: 'accounts' }) chartOfAccounts;

  get typeName() {
    return this.accountType.replace('Plutus::', '');
  }
}
