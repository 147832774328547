import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknFormsTfeIndexRoute extends Route {
  @service store;
  @service session;
  @service router;

  queryParams = {
    page: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    query: {
      refreshModel: true
    }
  };

  beforeModel() {
    if (!this.session.currentClient.can_view_form_in_tfe) {
      this.router.transitionTo('authenticated.crakn.manage.forms');
    }
  }

  model(params) {
    return this.store.query('tfe/document', {
      page: params.page,
      per_page: params.per_page,
      include: 'latest_revision',
      query: params.query
    });
  }

  setupController(controller, model) {
    controller.set('documents', model);
    controller.set('meta', model.meta);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.setProperties({
        query: null,
        meta: null
      });
    }
  }
}
