import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2KaseIntegrationModel extends Model {
  @attr('string') serviceName;
  @attr('string') status;
  @attr('string') tcoExportResponse;

  @belongsTo('v2/kase', { async: true }) kase;
  @belongsTo('v2/integrationService', { async: true }) integrationService;
}
