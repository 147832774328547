import Route from '@ember/routing/route';

export default class AdminGeneralPriceListPackagesEditRoute extends Route {
  model(params) {
    return this.store.findRecord('package', params.package_id);
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');

    controller.set('generalPriceList', gpl);
    controller.set('package', model);
    controller.set('packageSlots', model.get('packageSlots'));
    controller.set('packageSlotProducts', model.get('packageSlotProducts'));
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        package: null,
        generalPriceList: null,
        packageSlots: null,
        packageSlotProducts: null
      });
    }
  }
}
