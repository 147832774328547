import Controller from '@ember/controller';
import { camelize } from '@ember/string';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { sort, readOnly, bool, reads, not } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';

const Validations = buildValidations({
  'newAdjustment.amount': [
    validator('presence', {
      presence: true
    }),
    validator('number', {
      allowString: true,
      gt: 0,
      message: 'Must be greater than zero',
      positive: true
    })
  ],
  adjustmentTypeId: validator('presence', {
    presence: true,
    disabled: reads('model.isLineItem')
  }),
  'newAdjustment.adjustmentLineItemId': validator('presence', {
    presence: true,
    disabled: not('model.isLineItem')
  }),
  'newAdjustment.collectedAt': validator('presence', {
    presence: true,
    message:'Date can\'t be empty or invalid'
  }),
  'newAdjustment.gain': validator('presence', {
    presence: true,
    disabled: not('model.isLineItem')
  })
});

export default class KasesManageFinancialsAdjustmentsController extends Controller.extend(Validations) {
  @service session;
  @service staff;
  @service flashes;
  @service intl;

  selection_id = null;

  @tracked showValidation = false;
  
  @bool('newAdjustment.lineItemAdjustment') isLineItem;
  
  @readOnly('newAdjustment.adjustmentType.id') adjustmentTypeId;

  @sort('selection.adjustments', (a, b) => {
    const collectedDiff = b.get('collectedAt') - a.get('collectedAt');
    const enteredDiff = b.get('createdAt') - a.get('createdAt');
    
    return collectedDiff || enteredDiff;
  }) sortedAdjustments;

  get lineItems() {
    if (!this.selection) {
      return [];
    }
  
    const productsArray = this.selection.get('products')?.toArray() || [];
    const customProductsArray = this.selection.get('customProducts')?.toArray() || [];
    const selectionPackageSlotProductsArray = this.selection.get('selectionPackageSlotProducts')?.toArray() || [];
  
    const items = [
      ...productsArray,
      ...customProductsArray,
      ...selectionPackageSlotProductsArray
    ];
  
    return items.map(i => {
      let prefix = 'SPSP';
      if (i.constructor.modelName === 'selection-product') {
        prefix = 'SP';
      } else if (i.constructor.modelName === 'selection-custom-product') {
        prefix = 'SCP';
      }

      const itemId = `${prefix}${i.get('id')}`;
      i.set('itemId', itemId);

      return i;
    });
  }

  get adjustmentsEnabled() {
    return this.selection_id && !this.selection.get('exported')
      ? true
      : false;
  }

  get lineItem() {
    const itemId = this.newAdjustment.get('adjustmentLineItemId');
    
    if (itemId === undefined) {
      return null;
    }

    return this.lineItems.findBy('itemId', itemId);
  }

  get contractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice ? 
    'Choose an Invoice or Add-on' : 'Choose a Contract or Add-on';
  }

  get unpostedLabel() {
    if (!this.selection.isContract) {
      return 'Add-on';
    }
    return this.session.currentClient.relabel_contract_to_invoice ? 
    'Invoice' : 'Contract';
  }

  get adjustmentLockLabel() {
    return `Adjustments can only be created when 
    ${this.session.currentClient.relabel_contract_to_invoice ? 'an invoice' : 'a contract'}
     or add-on is posted`;
  }

  get isLineItemAdjustmentGreaterThanAmount() {
    const lineItemAdjustment = this.lineItems.findBy('itemId', this.newAdjustment.adjustmentLineItemId); 
  
    if (this.newAdjustment.adjustmentLineItemId) {
      return this.newAdjustment.amount > (lineItemAdjustment.selectionPackage ? lineItemAdjustment.get('product.price') : lineItemAdjustment.multipliedPrice);
    } else {
      return null;
    }
  }

  @action
  setAdjustmentGain(value) {
    switch (value) {
      case 'false':
        this.newAdjustment.gain = false;
        break;
      case 'true':
        this.newAdjustment.gain = true;
        break;
      default:
        this.newAdjustment.gain = null;
    }
  }

  @action
  lineItemChanged() {
    this.set('newAdjustment.includeItemTax', !this.get('lineItem.isTaxed'));

    if (this.lineItem?.isTaxed) {
      this.setAdjustmentGain('false');
    }
  }

  @action
  validateNewAdjustment(newAdjustment) {
    if (dateInCoaLockedPeriod(this.selection, this.session, newAdjustment.get('collectedAt'))) {
      this.flashes.addError(this.intl.t('authenticated.kases.manage.financials.adjustments.errors.postingPeriodAdd'));
      return;
    }

    if (this.isLineItem && this.isLineItemAdjustmentGreaterThanAmount) {
      this.set('showValidation', true);
    } else {
      this.validate().then(({ validations }) => {
        if (validations.isValid) {
          set(this, 'showValidation', false);
          if (newAdjustment.lineItemAdjustment) {
            set(
              newAdjustment,
              'amount',
              Math.abs(this.newAdjustment.get('amount'))
            );

            const adjLineItemId = newAdjustment.adjustmentLineItemId;
            if (adjLineItemId) {
              const item = this.lineItems.findBy('itemId', adjLineItemId);
              const modelName = camelize(item.constructor.modelName).replace(/^v2\//, '');
              newAdjustment.set(modelName, item);
            }
          }
          this.send('saveNewAdjustment', newAdjustment);
        } else {
          this.set('showValidation', true);
        }
      });
    }
  }

  @action
  setAdjustedBy(selection) {
    const staffMember = this.staff.active.findBy('id', selection);
    this.newAdjustment.set('adjustedBy', staffMember);
  }
}
