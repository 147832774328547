import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminGeneralPriceListsEditPackagesNewRoute extends Route {
  @service store;

  model() {
    return this.store.createRecord('package', {
      generalPriceListId: this.modelFor('authenticated.admin.general-price-lists.edit')
    });
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.set('generalPriceList', this.modelFor('authenticated.admin.general-price-lists.edit'))
  }
}
