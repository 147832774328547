import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesManageIntegrationsIndexRoute extends Route {
  @service api;
  @service flashes;
  @service router;
  @service session;

  layout = 'custom-aside';

  model() {
    return this.modelFor('authenticated.kases.manage.integrations');
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const tcoKaseExported = model.tcoId && model.tcoId !== '';
    controller.set('tcoKaseExported', tcoKaseExported);

    if ((this.session.currentClient.can_view_tco || this.session.currentClient.can_view_tco_v2) && tcoKaseExported) {
      this.api.json.get(`tco/kases/${model.id}/external_case`).then((response) => {
        controller.set('tcoKase', response.ok ? response.parsedJson : undefined);
      }).catch(() => {
        this.flashes.addError('There was a problem getting the case from TCO.');
      })
    }

    controller.set('tributeVideoExport', this.store.queryRecord('integrationExport', {
      kase_id: model.id,
      type: 'tribute_video',
      most_recent_export: true
    }));

    controller.set('integrationServices', this.store.query('integrationService', {
      kase_id: model.id,
      not_category: ['accounting', 'answering_service'],
      per_page: 100,
      status: 1 // successful 
    }));
  }
}
