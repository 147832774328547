import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class UserConfigService extends Service {
  @service intl;

  get adminNavigationCategory() {
    return this.intl.t('authenticated.admin.users.edit.feature-configuration.categories.adminNavigation');
  }

  get caseSectionNavigationCategory() {
    return this.intl.t('authenticated.admin.users.edit.feature-configuration.categories.caseSectionNavigation');
  }

  get globalNavigationCategory() {
    return this.intl.t('authenticated.admin.users.edit.feature-configuration.categories.globalNavigation');
  }

  categories = [
    this.intl.t('authenticated.admin.users.edit.feature-configuration.categories.all'),
    this.adminNavigationCategory,
    this.globalNavigationCategory
  ];

  adminNavigationFeatures = [
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewUsers'),
      value: 'canViewUsers'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewGroups'),
      value: 'canViewGroups'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewMiscTasks'),
      value: 'canViewMiscTasks'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewOrganizations'),
      value: 'canViewOrganizations'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewLocations'),
      value: 'canViewLocations'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewVehicles'),
      value: 'canViewVehicles'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewFinancialConfigurations'),
      value: 'canViewFinancialConfigurations'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewGeneralPriceLists'),
      value: 'canViewGeneralPriceLists'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewFormsAdmin'),
      value: 'canViewFormsAdmin'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewData'),
      value: 'canViewData'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewInfo'),
      value: 'canViewInfo'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewObituaryGenerator'),
      value: 'canViewObituaryGenerator'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewTributeVideo'),
      value: 'canViewTributeVideo'
    },
    {
      category: this.adminNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canSetupTributePay'),
      value: 'canSetupTributePay'
    }
  ]

  caseSectionNavigation = [
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewReferralInfo'),
      value: 'canViewReferralInfo'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewPreneed'),
      value: 'canViewPreneed'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewTransportation'),
      value: 'canViewTransportation'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewPreparations'),
      value: 'canViewPreparations'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewFamilyPortal'),
      value: 'canViewFamilyPortal'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewForms'),
      value: 'canViewForms'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewVeteranInfo'),
      value: 'canViewVeteranInfo'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewDisposition'),
      value: 'canViewDisposition'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewFamilyFriends'),
      value: 'canViewFamilyFriends'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewEvents'),
      value: 'canViewEvents'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewServiceInfo'),
      value: 'canViewServiceInfo'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewDocuments'),
      value: 'canViewDocuments'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewPrintables'),
      value: 'canViewPrintables'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewObituary'),
      value: 'canViewObituary'
    },
    {
      category: this.caseSectionNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewInquiriesCase'),
      value: 'canViewInquiriesCase'
    },
    {
      category: this.intl.t('authenticated.admin.users.edit.feature-configuration.categories.caseSectionNavigation'),
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewCustomCaseFields'),
      value: 'canViewCustomCaseFields'
    }
  ]

  globalNavigationFeatures = [
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewKases'),
      value: 'canViewKases'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewContacts'),
      value: 'canViewContacts'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewWhiteboard'),
      value: 'canViewWhiteboard'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewCalendar'),
      value: 'canViewCalendar'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewReports'),
      value: 'canViewReports'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewAccounting'),
      value: 'canViewAccounting'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewFinancialDetail'),
      value: 'canViewFinancialDetail'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewInquiriesGlobal'),
      value: 'canViewInquiriesGlobal'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewPlaces'),
      value: 'canViewPlaces'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewPayments'),
      value: 'canViewPayments'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewGrowthEngine'),
      value: 'canViewGrowthEngine'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewOhioEdrs'),
      value: 'canViewOhioEdrs'
    },
    {
      category: this.globalNavigationCategory,
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewTCO'),
      value: 'canViewTCO'
    },
    {
      category: this.globalNavigationCategory,
      conditionalClientValue: 'can_view_online_planner',
      labelText: this.intl.t('authenticated.admin.users.edit.feature-configuration.checkboxes.canViewOnlinePlannerPlans'),
      value: 'canViewOnlinePlannerPlans'
    }
  ]

  allFeatures = [
    ...this.adminNavigationFeatures,
    ...this.caseSectionNavigation,
    ...this.globalNavigationFeatures
  ];

  @tracked features = this.allFeatures;
  @tracked currentFilter = this.intl.t('authenticated.admin.users.edit.feature-configuration.categories.all');

  filterFeatures(categoryType) {
    this.currentFilter = categoryType;

    if (categoryType == this.intl.t('authenticated.admin.users.edit.feature-configuration.categories.all')) {
      this.features = this.allFeatures;
    } else {
      this.features = this.allFeatures.filter(
        (feature) => feature.category === categoryType
      );
    }
  }

  searchFeatures(value) {
    if (this.currentFilter !== this.intl.t('authenticated.admin.users.edit.feature-configuration.categories.all')) {
      this.currentFilter = this.intl.t('authenticated.admin.users.edit.feature-configuration.categories.all');
    }

    this.features = this.allFeatures.filter((feature) =>
      feature.labelText.toLowerCase().includes(value.toLowerCase())
    );
  }
}
