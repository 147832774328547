import Controller from '@ember/controller';
import config from 'crakn/config/environment';
import moment from 'moment';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads, not } from '@ember/object/computed';

const Validations = buildValidations({
  'model.tcoKase.Email': [
    validator('presence', {
      presence: true,
      message: reads('model.emailPresenceErrorMessage'),
      disabled: not('model.model.tcoKase.PrivateGuestBook')
    }),
    validator('format', {
      regex: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
      message: reads('model.emailFormatErrorMessage'),
      disabled: not('model.model.tcoKase.PrivateGuestBook')
    })
  ]
});

export default class AuthenticatedKasesManageIntegrationsTcoV2Controller extends Controller.extend(Validations) {
  @service api;
  @service flashes;
  @service intl;

  @tracked invalidLegacyTouchPin = false;
  @tracked legacyTouchPin = null;
  @tracked legacyTouchPinData = null;
  @tracked pinToDelete = false;
  @tracked postToFacebook = true;
  @tracked showLegacyTouchDialog = false;
  @tracked showValidations = false;

  fbTimer = null;
  fbWindow = null;
  listenToFbWindowClose = this.setServingLocationHasFacebookToken.bind(this);

  get formattedTcoExportedAt() {
    return moment(this.tcoExport.updatedAt).format('LLL');
  }

  get previewLink() {
    return `${this.model.integrationService.get('website_url')}/obituary/preview?obId=${this.model.kase.get('tcoId')}`;
  }

  get isConnectedToFacebook() {
    return this.model.tcoKase?.ServingLocation?.HasFacebookToken;
  }

  get forceToHomePage() {
    return this.model.tcoKase ? this.model.tcoKase?.ForceToHomePage : undefined;
  }

  get pdfFileExtension() {
    return ['pdf'];
  }

  get pdfUploadRoute() {
    return this.api.url(`tco/kases/${this.model.kase.get('id')}/pdf_upload`);
  }

  get matchingPinMessage() {
    return this.intl.t('authenticated.kases.manage.integrations.tco-v2.sections.legacyTouch.dialogModals.confirm.messages.matchingPins', {
          legacyTouchPinFirstName: this.legacyTouchPinData.FirstName,
          legacyTouchPinLastName: this.legacyTouchPinData.LastName,
          deceasedName: this.model.kase.get('deceased.name')
        });
  }

  get shouldWarnPostingToFacebook() {
    const hasntBeenPostedToFB = !this.model.tcoKase.IsAutosharedToFacebook;
    const weWantToPostToFB = this.model.tcoKase.PostToFacebook === true;
    const noPhoto = !this.model.tcoKase.ThumbnailUrl;
    const publishing = this.model.tcoKase.IsPublished;
    
    return hasntBeenPostedToFB && weWantToPostToFB && noPhoto && publishing;
  }

  get facebookPromptMessage() {
    return this.intl.t('authenticated.kases.manage.integrations.tco-v2.sections.socialSharing.warning');
  }

  get emailPresenceErrorMessage() {
    return this.intl.t('authenticated.kases.manage.integrations.tco-v2.validations.email.cannotBeBlank');
  }

  get emailFormatErrorMessage() {
    return this.intl.t('authenticated.kases.manage.integrations.tco-v2.validations.email.mustBeValidEmail');
  }

  async setServingLocationHasFacebookToken() {
    if(this.fbWindow.closed) {
      clearInterval(this.fbTimer);
      this.fbWindow = null;

      const resp = await this.api.json.get(`tco/kases/${this.model.kase.get('id')}/external_case`);

      if (!resp.ok || resp?.parsedJson?.ServingLocationId !== this.model.tcoKase?.ServingLocationId) {
        return;
      }

      set(this.model.tcoKase.ServingLocation, 'HasFacebookToken', resp.parsedJson.ServingLocation.HasFacebookToken);

      this.forcePageReRender();
    }
  }

  forcePageReRender() {
    this.model = { ...this.model };
  }

  @action
  publishChanged() {
    set(this.model.tcoKase, 'IsPublished', !this.model.tcoKase.IsPublished);
    this.forcePageReRender();
  }

  @action
  connectToFacebook() {
    if (!config.tcoUrl) {
      this.flashes.addError(
        this.intl.t('authenticated.kases.manage.integrations.tco-v2.sections.socialSharing.errors.environmentUrlNotSet')
      );

      return;
    }

    if (!this.model.tcoKase.ServingLocationId) {
      this.flashes.addError(
        this.intl.t('authenticated.kases.manage.integrations.tco-v2.sections.socialSharing.errors.missingServiceLocation')
      );

      return;
    }

    this.fbWindow =  window.open(`${config.tcoUrl}SocialMedia/FacebookAuth?locationId=${this.model.tcoKase.ServingLocationId}`)
    this.fbTimer = setInterval(this.listenToFbWindowClose, 1000)
  }

  @action
  homepagePositionChange(value) {
    set(this.model.tcoKase, 'ForceToHomePage', Number(value));
    this.forcePageReRender();
  }

  @action
  pdfUploaded(response) {
    set(this.model.tcoKase, 'FileLink', response.url);
    this.forcePageReRender();
  }

  @action
  themeSelected(theme) {
    set(this.model.tcoKase, 'ThemeId', theme.Id);
  }

  @action
  hideLegacyTouchDialog() {
    set(this, 'showLegacyTouchDialog', false);
  }

  @action
  async addLegacyTouchPin() {
    const resp = await this.api.json.post(`tco/kases/${this.model.kase.get('id')}/legacy_touch/confirm`, {
      body: { pin: this.legacyTouchPin }
    });

    if (resp.ok) {
      const legacyResp = await this.api.json.get(`tco/kases/${this.model.kase.get('id')}/legacy_touch`);
      
      set(this.model, 'legacyTouchPins', legacyResp.parsedJson);
      set(this, 'legacyTouchPin', undefined);

      this.flashes.addSuccess(
        this.intl.t('authenticated.kases.manage.integrations.tco-v2.sections.legacyTouch.dialogModals.confirm.success')
      );

      this.forcePageReRender();
    } else {
      this.flashes.addError(
        this.intl.t('authenticated.kases.manage.integrations.tco-v2.sections.legacyTouch.dialogModals.confirm.error')
      );
    }

    set(this, 'showLegacyTouchDialog', false);
  }

  @action
  async deletePin() {
    const resp = await this.api.json.delete(`tco/kases/${this.model.kase.get('id')}/legacy_touch/delete/${this.pinToDelete.Id}`);

    if (resp.ok) {
      set(this.model, 'legacyTouchPins', this.model.legacyTouchPins?.filter(x => x.Id !== this.pinToDelete.Id));

      this.flashes.addSuccess(
        this.intl.t('authenticated.kases.manage.integrations.tco-v2.sections.legacyTouch.dialogModals.delete.success')
      );

      this.forcePageReRender();
    } else {
      this.flashes.addError(
        this.intl.t('authenticated.kases.manage.integrations.tco-v2.sections.legacyTouch.dialogModals.delete.error')
      );
    }

    set(this, 'pinToDelete', undefined);
  }

  @action
  async validateLegacyTouchPin(pin) {
    set(this, 'invalidLegacyTouchPin', false);

    const response = await this.api.json.get(`tco/kases/${this.model.kase.get('id')}/legacy_touch/validate/${pin}`);

    set(this, 'legacyTouchPinData', response.parsedJson);

    if (!this.legacyTouchPinData.IsValid) {
      set(this, 'invalidLegacyTouchPin', true);
      return;
    }

    set(this, 'showLegacyTouchDialog', true);
  }

  async saveTcoKase() {
    set(this.model.tcoKase, 'PostToFacebook', this.postToFacebook);

    if (this.shouldWarnPostingToFacebook && !confirm(this.facebookPromptMessage)) {
      return;
    }

    const resp = await this.api.json.put(
      `tco/kases/${this.model.kase.id}`,
      { body: this.model.tcoKase }
    );

    if (!resp.ok) {
      this.flashes.addError(
        this.intl.t('authenticated.kases.manage.integrations.tco-v2.errors.save')
      );

      return;
    }

    set(this.model, 'isPublishedState', this.model.tcoKase.IsPublished)

    this.flashes.addSuccess(
      this.intl.t('authenticated.kases.manage.integrations.tco-v2.successes.save')
    );

    this.forcePageReRender();
  }

  @action
  save() {
    this.validate().then(({validations}) => {
      if (validations.isValid) {
        this.saveTcoKase();
      } else {
        set(this, 'showValidations', true);
      }
    });
  }
}
