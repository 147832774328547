import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2PackageSlotProductModel extends Model {
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') updatedAt;

  @belongsTo('v2/client', { async: true }) client;
  @belongsTo('v2/package-slot', { async: true }) packageSlot;
  @belongsTo('v2/product', { async: true }) product;
}
