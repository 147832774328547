export default {
  aftercare: {
    name: 'aftercare'
  },
  bassMollett: {
    name: 'bass_mollett'
  },
  batesville: {
    name: 'batesville'
  },
  bestWishes: {
    name: 'best_wishes'
  },
  cfs: {
    name: 'cfs'
  },
  ciclops: {
    name: 'ciclops'
  },
  tributePrint: {
    name: 'tribute_print'
  },
  forethought: {
    name: 'forethought'
  },
  frazer: {
    name: 'frazer'
  },
  frontrunner: {
    name: 'frontrunner'
  },
  funeralDecisions: {
    name: 'funeral_decisions'
  },
  funeralone: {
    name: 'funeralone'
  },
  funeraltech: {
    name: 'funeral_tech'
  },
  messenger: {
    name: 'messenger'
  },
  ohioEdrs: {
    name: 'ohio_edrs'
  },
  precoa: {
    name: 'precoa'
  },
  transnationalBank: {
    name: 'transnational_bank'
  },
  tributeVideo: {
    name: 'tribute_video'
  },
  tukios: {
    name: 'tukios'
  },
  tco: {
    name: 'tco'
  },
  tcoV2: {
    name: 'tco_v2'
  },
  viewlogies: {
    name: 'viewlogies'
  }
}
