import Model, { belongsTo } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'tag.id': validator('presence', {
    presence: true,
    message: 'A tag product category must have a tag.'
  }),
  'account.id': validator('presence', {
    presence: true,
    message: 'A tag must have an account.'
  })
});

export default class V2TagProductCategoryModel extends Model.extend(Validations) {
  @belongsTo('v2/tag') tag;
  @belongsTo('v2/productCategory') productCategory;

  @belongsTo('v2/account') account;
  @belongsTo('v2/account') assetAccount;
  @belongsTo('v2/account') cogsAccount;
}
