import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and this.showValidations this.validationMessages.length)}}\n  <div class=\"validation__error\">\n    {{#each this.validationMessages as |message|}}\n      <p>{{message}}</p>\n    {{/each}}\n  </div>\n{{/if}}\n", {"contents":"{{#if (and this.showValidations this.validationMessages.length)}}\n  <div class=\"validation__error\">\n    {{#each this.validationMessages as |message|}}\n      <p>{{message}}</p>\n    {{/each}}\n  </div>\n{{/if}}\n","moduleName":"crakn/components/g/crakn/field/validation.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/field/validation.hbs"}});
import Component from '@glimmer/component';
/**
 * Displays validation messages in a single string or an array
 * 
 * 
 * ## Arguments
 * - **validationMessages** =  single string or array of string to display as error messages
 * - **showValidations** *default: true* = a boolean whether or not to display validation messages 

 */

export default class GCraknFieldValidationComponent extends Component {
  get validationMessages() {
    const validationMessages = this.args.validationMessages;

    if (!validationMessages) {
      return [];
    }

    return Array.isArray(validationMessages) ? validationMessages : [validationMessages];
  }

  get showValidations() {
    return this.args.showValidations === undefined ? true : this.args.showValidations;
  }
}
