import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'account.id': validator('presence', {
    presence: true,
    message: 'A product category must have an account.'
  }),
  'name': validator('presence', {
    presence: true,
    message: 'A product category must have a name.'
  })
});

export default class V2ProductCategoryModel extends Model.extend(Validations) {
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('string') description;
  @attr('string') name;
  @attr('number') customOrder;
  @attr('boolean') removeAccount;

  @belongsTo('v2/account') account;
  @belongsTo('v2/account') assetAccount;
  @belongsTo('client') client;
  @belongsTo('v2/account') cogsAccount;
  @belongsTo('v2/generalPriceList') generalPriceList;
  @belongsTo('v2/preneedCategory') preneedCategory;

  @hasMany('v2/tagProductCategory') tagProductCategories;
  @hasMany('product') products;
}
