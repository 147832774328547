import Model, { attr, belongsTo } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default class V2SelectionPackageSlotProductModel extends Model {
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') updatedAt;
  @attr('string') notes;
  @attr('price') price;

  @belongsTo('v2/selection-package-slot', { async: true }) selectionPackageSlot;
  @belongsTo('v2/product', { async: true }) product;

  @alias('product.isTaxed') isTaxed;
}
