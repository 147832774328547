import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2PackageSlotModel extends Model {
  @attr('boolean') required;

  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') updatedAt;

  @attr('number') allowanceAmount;

  @attr('string') packageSlotType;

  @belongsTo('v2/account', { async: true }) unspentAllowanceAccount;
  @belongsTo('v2/package', { async: true }) package;
  @belongsTo('v2/product-category', { async: true }) productCategory;

  @hasMany('v2/selection-package-slot') selectionPackageSlots;
  @hasMany('v2/package-slot-product', { async: true }) packageSlotProducts;

}
